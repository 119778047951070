// src/hooks/useImageDuration.js
import { useState } from "react";
import axios from "axios";
import { useAuthContext } from "./useAuthContext";

const useImageDuration = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useAuthContext();

  const updateDuration = async (videoId, imageId, duration) => {
    setLoading(true);
    setError(null);
    try {
      if (!user) {
        alert("You must be logged in to update the image");
        return;
      }
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/video/${videoId._id}/assets/${imageId}/duration`,
        { imgDuration: duration },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      setError(error);
      throw error;
    }
  };

  const getImageDuration = async (videoId, imageId) => {
    setLoading(true);
    setError(null);
    try {
      if (!user) {
        alert("You must be logged in to fetch the image duration");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/video/${videoId._id}/assets/${imageId}/duration`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setLoading(false);
      return response.data.duration;
    } catch (error) {
      setLoading(false);
      setError(error);
      throw error;
    }
  };

  return { updateDuration, getImageDuration, loading, error };
};

export default useImageDuration;
