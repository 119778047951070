import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  AppBar,
  CircularProgress,
  Alert,
} from "@mui/material";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import Dashboardnavbar from "../Dashboard/Dashboardnavbar";
import { Link } from "react-router-dom";
import logo from "../../Images/logo1.png";
import { useSendContact } from "../../Hooks/UseSendContact";

function ContactPage() {
  const { sendContact, error, isLoading } = useSendContact();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const success = await sendContact(firstname, lastname, email, message);

    if (success) {
      setSuccessMessage("Your message has been sent successfully.");
      setFirstname("");
      setLastname("");
      setEmail("");
      setMessage("");
    }
  };

  return (
    <>
      {/* ===================== navbar =====================*/}
      <AppBar position="static" sx={{ backgroundColor: "#000" }}>
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"space-between"}
          py={2}
        >
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Link to="/">
              <img src={logo} alt="logo" width="300px" />
            </Link>
          </Box>
          <Box>
            <Dashboardnavbar />
          </Box>
        </Box>
      </AppBar>
      {/* ===================== main =====================*/}
      <Box
        sx={{
          backgroundColor: "black",
          color: "white",
          height: "auto",
          maxWidth: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid
            item
            xs={12}
            sm={8}
            width={"100%"}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: "0 20px",
              }}
            >
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  maxWidth: "400px",
                }}
                onSubmit={handleSubmit}
              >
                <Typography
                  variant="h4"
                  sx={{
                    alignSelf: "flex-start",
                    textAlign: "left",
                    margin: "0px 0 30px 0",
                  }}
                >
                  Contact
                </Typography>
                <Typography
                  variant="body1"
                  component="label"
                  htmlFor="name"
                  sx={{ mb: 1, fontSize: "15px" }}
                >
                  First name and Last name:
                </Typography>
                <Box display={"flex"} gap={2}>
                  <TextField
                    type="text"
                    name="firstname"
                    placeholder="First name"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    required
                    sx={{
                      mb: 2,
                      width: "50%",
                      border: "1px solid rgb(133, 133, 133)",
                      borderRadius: 1,
                      backgroundColor: "black",
                      color: "white",
                      fontSize: "10px",
                    }}
                    InputProps={{
                      style: {
                        color: "white",
                      },
                    }}
                  />
                  <TextField
                    type="text"
                    name="lastname"
                    placeholder="Last name"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                    required
                    sx={{
                      mb: 2,
                      width: "50%",
                      border: "1px solid rgb(133, 133, 133)",
                      borderRadius: 1,
                      backgroundColor: "black",
                      color: "white",
                      fontSize: "10px",
                    }}
                    InputProps={{
                      style: {
                        color: "white",
                      },
                    }}
                  />
                </Box>
                <Typography style={{ fontSize: "1rem" }} htmlFor="email">
                  Email Address:
                </Typography>
                <TextField
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  sx={{
                    marginBottom: "12px",
                    backgroundColor: "black",
                    input: { color: "white" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                  }}
                />
                <Typography style={{ fontSize: "1rem" }} htmlFor="message">
                  Message:
                </Typography>
                <TextField
                  multiline
                  rows={4}
                  name="message"
                  placeholder="Your Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  sx={{
                    marginBottom: "12px",
                    backgroundColor: "black",
                    textarea: { color: "white" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                  }}
                />
                {isLoading ? (
                  <Box display="flex" justifyContent="center" sx={{ my: 2 }}>
                    <CircularProgress color="inherit" />
                  </Box>
                ) : (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      borderRadius: "20px",
                      padding: "8px",
                      backgroundColor: "rgba(98, 255, 180, 1)",
                      color: "black",
                    }}
                  >
                    Submit
                  </Button>
                )}
                {error && (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    {error}
                  </Alert>
                )}
                {successMessage && (
                  <Alert severity="success" sx={{ mt: 2 }}>
                    {successMessage}
                  </Alert>
                )}
              </form>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
              <Box
                sx={{
                  marginTop: { xs: "50px", md: "150px" },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "20px",
                }}
                gap={3}
              >
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginBottom: "16px",
                  }}
                >
                  <strong>E-mail:</strong>
                  <Typography>ali2200@gmail.com</Typography>
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginBottom: "16px",
                  }}
                >
                  <strong>Phone:</strong>
                  <Typography>0423 983 244</Typography>
                </Typography>
                {/* <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginBottom: "16px",
                  }}
                >
                  <strong>Location:</strong>
                  <Typography>New York, California, Ohio</Typography>
                </Typography> */}
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "16px",
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                  gap={5}
                >
                  <Facebook fontSize="large" />
                  <Instagram fontSize="large" />
                  <Twitter fontSize="large" />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ContactPage;
