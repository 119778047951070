import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import axios from "axios";

export const useSignup = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = useAuthContext();

  const signup = async (firstName, lastName, email, password) => {
    setIsLoading(true);
    setError(null);
    axios
      .post(`${process.env.REACT_APP_SERVER_DOMAIN}/api/auth/create`, {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
      })
      .then((response) => {
        console.log("ayoooo");
        // save the user to local storage
        localStorage.setItem("user", JSON.stringify(response.data));
        // update auth context
        dispatch({ type: "LOGIN", payload: response.data });
        // loading to false
        setIsLoading(false);
        return true;
      })
      .catch((error) => {
        console.log("eroorrrr");
        setIsLoading(false);
        setError(error.response.data.error || "Signup failed");
        return false;
      });
  };

  return { signup, error, isLoading };
};
