import React, { useContext } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import "../../../Styles/PopupWindow.css"; // Ensure this file contains the necessary styles for blur effect and modal content
import { PopupWindowContext } from "../../../Context/PopupWindowContext";

const stepData = [
  { number: 1, text: "Choose Backgrounds of your Video" },
  { number: 2, text: "Select Aspect ratio or Dimension of your video" },
  { number: 3, text: "Select Background Music of the Video" },
  { number: 4, text: "Upload Photos of the Video" },
];

function Steps() {
  const { stepNumber } = useContext(PopupWindowContext);
  const theme = useTheme();
  const isXsOrSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box display="flex" justifyContent="center">
      <Box className="stepper" gap={1} width="80%">
        {stepData.map((step) => (
          <Box
            key={step.number}
            className={`step ${stepNumber === step.number ? "active" : ""}`}
            sx={{
              display: isXsOrSm && stepNumber !== step.number ? "none" : "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography className="circle">{step.number}</Typography>

            <Typography
              variant="subtitle2"
              component="div"
              textAlign="center"
              mt={1}
              sx={{
                opacity: "70%",
                lineHeight: "21.9px",
                fontWeight: stepNumber === step.number ? "600" : "400",
                fontSize: {
                  xs: "0.7rem",
                  sm: "0.7rem",
                  md: "0.8rem",
                  lg: "0.9rem",
                },
              }}
            >
              {step.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Steps;
