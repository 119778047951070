import React from "react";
import { Box, Button, Typography } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { useNavigate } from "react-router-dom";

const CancelPayment = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/dashboard");
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="#181C1F"
      color="#FFFFFF"
      textAlign="center"
      padding={4}
    >
      <Box
        bgcolor="#000000"
        padding={4}
        borderRadius={2}
        boxShadow={3}
        sx={{
          "&:hover": {
            boxShadow: 6,
          },
        }}
      >
        <ErrorOutlineOutlinedIcon color="error" sx={{ fontSize: 60 }} />
        <Typography variant="h4" gutterBottom>
          Payment Canceled
        </Typography>
        <Typography variant="body1" gutterBottom>
          Your payment has been canceled. Please try again.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGoBack}
          sx={{ marginTop: 4 }}
        >
          Go Back
        </Button>
      </Box>
    </Box>
  );
};

export default CancelPayment;
