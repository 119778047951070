import { useVideoContext } from "../Context/VideoContext";
import { useAuthContext } from "./useAuthContext";
import axios from "axios";

export const useRenameVideo = () => {
  const { user } = useAuthContext();
  const { videoId } = useVideoContext();
  const RenameVideo = async (title) => {
    if (!user) {
      alert("You must be logged in to update the image");
      return;
    }

    try {
      await axios.patch(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/video/${videoId._id}/update-title`,
        {
          title: title,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log("Video renmae successfully");
    } catch (error) {
      console.error("Error renamin image:", error);
    }
  };

  return { RenameVideo };
};
