import React, { useEffect, useState } from "react";
import { Modal, Box, Button, Typography, Alert, Snackbar } from "@mui/material";
import "../../../Styles/PopupWindow.css";
import { PopupWindowContext } from "../../../Context/PopupWindowContext";
import { useNavigate } from "react-router-dom";
import Steps from "./Steps";
import Dropzone from "../Dropzone";
import AddSong from "../PopupWindow/AddSong";
import AddFormat from "../PopupWindow/AddFormat";
import HorizontalBackground from "./HorizontalBackground";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useCreateTemplateVideo } from "../../../Hooks/useCreateTemplateVideo";
import Loader from "../../LoadingScreen/Loader";

export default function WizardEdit({ onClose }) {
  const navigate = useNavigate();
  const [stepNumber, setStepNumber] = useState(1);
  const { handleGetStarted, error, setError, loading } =
    useCreateTemplateVideo();
  const [formState, setFormState] = useState({
    Background: null,
    AddSong: null,
    AddFormat: null,
    Dropzone: null,
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleClose = () => onClose();

  const handleNext = () => {
    let currentStepKey;

    switch (stepNumber) {
      case 1:
        currentStepKey = "Background";
        break;
      case 2:
        currentStepKey = "AddFormat";
        break;
      case 3:
        currentStepKey = "AddSong";
        break;

      default:
        currentStepKey = null;
    }
    console.log("current :", currentStepKey);
    console.log("current formstate :", formState["Dropzone"]);
    console.log(" formstate :", formState);
    if (formState[currentStepKey] === null) {
      setSnackbarMessage(`Please complete the ${currentStepKey} step.`);
      setSnackbarOpen(true);
      return; // Prevent going to the next step
    }

    setStepNumber(stepNumber + 1 > 4 ? 1 : stepNumber + 1);
  };

  const handleBack = () => {
    setStepNumber(stepNumber - 1 < 1 ? 4 : stepNumber - 1);
  };

  const goToEditor = async () => {
    console.log("eror ", error);
    console.log(" gotoeditor formstate :", formState);

    if (!formState["Dropzone"]) {
      setSnackbarMessage(`Please complete the Upload image step.`);
      setSnackbarOpen(true);
      return; // Prevent going to the next step
    }
    const success = await handleGetStarted(formState);
    if (success) {
      console.log("New video made Successfully !!!");
      navigate("/edit", { state: { previewActive: true } });
    } else {
      console.log("New video was not made");
    }
  };
  useEffect(() => {
    const isFormFilled = Object.values(formState).every(
      (value) => value !== null
    );
    if (isFormFilled) {
      goToEditor();
    }
  }, [formState]);

  return (
    <Box>
      <Modal
        open={true}
        onClose={handleClose}
        className="wizard-blur-background"
      >
        <Box
          className="modal-content"
          sx={{
            maxWidth: { xs: "85vw", md: "70vw", xl: "50vw" },
            maxHeight: { xs: "90vh", md: "80vh" },
          }}
        >
          <Typography display="flex" justifyContent="flex-end" width="100%">
            <Button onClick={handleClose} sx={{ color: "#62FFB4" }}>
              Close
            </Button>
          </Typography>
          {loading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="300px"
            >
              <Loader text="Setting up ..." backround="rgba(0, 0, 0, 0.3)" />
            </Box>
          )}
          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}
          {!loading && !error && (
            <PopupWindowContext.Provider
              value={{ stepNumber, setStepNumber, formState, setFormState }}
            >
              <Steps />
              <Box maxHeight="300px">
                {stepNumber === 1 && (
                  <HorizontalBackground component={"wizard"} />
                )}
                {stepNumber === 2 && (
                  <AddFormat component={"wizard"} handleClose={handleClose} />
                )}
                {stepNumber === 3 && <AddSong component={"wizard"} />}
                {stepNumber === 4 && (
                  <Dropzone component={"wizard"} onClose={() => {}} />
                )}
              </Box>
            </PopupWindowContext.Provider>
          )}
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography
              alignContent="center"
              variant="body2"
              maxWidth={"40%"}
              sx={{
                opacity: "70%",
                lineHeight: "21px",
                fontWeight: "500",
                fontSize: {
                  xs: "0.6rem",
                  sm: "0.7rem",
                  md: "0.7rem",
                  lg: "0.8rem",
                  xl: "0.9rem",
                },
              }}
            >
              You can always make changes later.
            </Typography>
            <Box>
              {stepNumber === 2 || stepNumber === 3 ? (
                <>
                  <Button
                    size="small"
                    onClick={handleBack}
                    sx={{
                      marginTop: 1,
                      color: "#62FFB4",
                      "& .MuiButton-startIcon": {
                        marginRight: "1px", // Adjust this value to bring the icon closer
                      },
                    }}
                    disableRipple
                    startIcon={<ChevronLeftIcon />}
                  >
                    BACK
                  </Button>
                  <Button
                    size="small"
                    onClick={handleNext}
                    sx={{ marginTop: 1, color: "#62FFB4" }}
                    disableRipple
                  >
                    Next
                  </Button>
                </>
              ) : stepNumber === 4 ? (
                <>
                  <Button
                    size="small"
                    onClick={handleBack}
                    sx={{
                      marginTop: 1,
                      color: "#62FFB4",
                      fontSize: {
                        xs: "0.6rem",
                        sm: "0.7rem",
                        md: "0.7rem",
                        lg: "0.8rem",
                        xl: "0.9rem",
                      },
                      "& .MuiButton-startIcon": {
                        marginRight: "1px", // Adjust this value to bring the icon closer
                      },
                    }}
                    disableRipple
                    startIcon={<ChevronLeftIcon />}
                  >
                    Back
                  </Button>
                  <Button
                    size="small"
                    onClick={goToEditor}
                    sx={{
                      marginTop: 1,
                      color: "#62FFB4",
                      fontSize: {
                        xs: "0.6rem",
                        sm: "0.7rem",
                        md: "0.7rem",
                        lg: "0.8rem",
                        xl: "0.9rem",
                      },
                    }}
                    disableRipple
                  >
                    Go to Editor
                  </Button>
                </>
              ) : (
                <Button
                  size="small"
                  onClick={handleNext}
                  sx={{
                    marginTop: 1,
                    color: "#62FFB4",
                  }}
                  disableRipple
                >
                  Next
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={snackbarMessage}
      />
    </Box>
  );
}
