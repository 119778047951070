import React, { useContext, useState } from "react";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useVideoContext } from "../../../Context/VideoContext"; // Adjust the path as needed
import { useFormatContext } from "../../../Context/FormatContext"; // Adjust the path as needed
import { useAuthContext } from "../../../Hooks/useAuthContext";
import { PopupWindowContext } from "../../../Context/PopupWindowContext";

function AddFormat({ component, handleClose }) {
  const { user } = useAuthContext();
  const { videoId } = useVideoContext();
  const { setFormat } = useFormatContext();
  const [selectedFormat, setSelectedFormat] = useState(null);

  const formats = [
    {
      text: "Square ",
      label: "1:1",
      description: "Best for social media posts",
      style: {
        width: { xs: "80px", sm: "96px" },
        height: { xs: "80px", sm: "96px" },
      },
    },
    {
      text: "Landscape ",
      label: "16:9",
      description: "Recommended for Large screen (PC, LAPTOP)",
      style: {
        width: { xs: "90px", sm: "134px" },
        height: { xs: "50px", sm: "70px" },
      },
    },
    {
      text: "Vertical ",
      label: "9:16",
      description: "Suitable for mobile screens (Social media, stories)",
      style: {
        width: { xs: "60px", sm: "90px" },
        height: { xs: "90px", sm: "130px" },
      },
    },
  ];
  const { setFormState } = useContext(PopupWindowContext);

  const handleFormatSelect = (index, format) => {
    setSelectedFormat(index);
    setFormState((prevState) => ({
      ...prevState,
      AddFormat: format.label,
    }));
  };

  const handleSelectFormat = (index, format) => {
    setSelectedFormat(index);
    handleDoubleClick(format);

    setTimeout(() => {
      handleClose();
    }, 500);
  };

  const handleDoubleClick = async (format) => {
    if (!user) {
      alert("You must be logged in to update the format");
      return;
    }

    try {
      await axios.patch(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/video/${videoId._id}/aspect-ratio`,
        {
          aspectRatio: format.label,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setFormat(format.label);
      console.log("Format updated successfully");
    } catch (error) {
      console.error("Error updating format:", error);
      alert("Failed to update format");
    }
  };

  return (
    <Box width="100%" display="flex" justifyContent="center">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "transparent",
          padding: "30px",
          width: "70%",
          minHeight: { xs: "0", md: "280px" },
          gap: { xs: 0.5, sm: 2 },
        }}
      >
        {formats.map((format, index) => (
          <Box key={index}>
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#181C1F",
                margin: "3px",
                minWidth: { xs: "105px", sm: "195px" },
                height: { xs: "125px", sm: "174px" },
                border: selectedFormat === index ? "2px solid #62FFB4" : "none",
                cursor: "pointer",
              }}
              onClick={() => {
                component === "wizard"
                  ? handleFormatSelect(index, format)
                  : handleSelectFormat(index, format);
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#364f47",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  ...format.style,
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#FFFFFF",
                    lineHeight: "21px",
                    fontWeight: "300",
                    marginBottom: "3px",
                    width: "80%",
                    textAlign: "center",
                    fontSize: { xs: "0.7em", sm: "0.8em", md: "0.9em" },
                  }}
                >
                  {format.text} {format.label}
                </Typography>
              </Box>
            </Box>
            <Box width="100%" display="flex" justifyContent="center" mt={1}>
              <Typography
                variant="body2"
                sx={{
                  color: "gray",
                  width: "90%",
                  textAlign: "center",
                  fontSize: { xs: "0.6em", sm: "0.7em", md: "0.8em" },
                  // display: { xs: "none", md: "block" },
                }}
              >
                {format.description}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default AddFormat;
