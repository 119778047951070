import React from "react";
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import Navbar from "./Navbar";
import HomepageFooter from "./HomepageFooter";
function Support() {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          backgroundColor: "primary",
          color: "white",
          minHeight: "100vh",
          padding: { xs: 3, sm: 4, md: 6 },
        }}
      >
        {/* Main Heading */}
        <Typography
          variant="h3"
          sx={{
            fontWeight: 600,
            fontSize: { xs: "1.8rem", sm: "2.4rem", md: "2.8rem" },
            textAlign: "left",
            marginBottom: 3,
          }}
        >
          Support
        </Typography>

        {/* Introductory Text */}
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
            lineHeight: 1.6,
            textAlign: "left",
            marginBottom: 4,
          }}
        >
          Welcome to the Support page for AngelicDove. We understand that
          creating a memorial video for a loved one can be a sensitive and
          emotional process. Our goal is to make it as smooth and supportive as
          possible. Below, you’ll find answers to frequently asked questions and
          ways to contact us for further assistance.
        </Typography>

        <Divider sx={{ my: 4, borderColor: "white" }} />

        {/* FAQ Section */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            mb: 2,
            fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2rem" },
          }}
        >
          Frequently Asked Questions (FAQs)
        </Typography>
        <List disablePadding sx={{ marginBottom: 4 }}>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="1. How do I start creating a memorial video?" />
            <Typography variant="body2" sx={{ color: "white", ml: 3 }}>
              Begin by uploading 10 to 60 photos that you would like to include
              in the video. Once the photos are uploaded, you can arrange them
              as you prefer, select a background, and choose a song. You’ll then
              be able to preview the video with a watermark before purchasing.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="2. Can I edit my photos after uploading them?" />
            <Typography variant="body2" sx={{ color: "white", ml: 3 }}>
              Yes, you can rearrange the order of photos and make basic
              adjustments.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="3. What backgrounds and songs are available?" />
            <Typography variant="body2" sx={{ color: "white", ml: 3 }}>
              We offer several backgrounds, including options like the sea,
              clouds, mountains, and waterfalls. There are also five carefully
              selected non-copyrighted songs to choose from.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="4. How does the preview and purchase process work?" />
            <Typography variant="body2" sx={{ color: "white", ml: 3 }}>
              Once you’ve completed the video customization, you’ll be able to
              preview the video with a watermark. If you’re happy with the
              result, you can proceed with payment via Stripe.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="5. How long does it take to receive the final video?" />
            <Typography variant="body2" sx={{ color: "white", ml: 3 }}>
              After payment, final video processing typically takes a few
              minutes. You’ll receive an email with the download link as soon as
              it’s ready.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="6. What if I encounter technical issues during the process?" />
            <Typography variant="body2" sx={{ color: "white", ml: 3 }}>
              If you run into any issues, our support team is here to help.
              Please contact us using one of the methods below.
            </Typography>
          </ListItem>
        </List>

        <Divider sx={{ my: 4, borderColor: "white" }} />

        {/* Contact Information */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            mb: 2,
            fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2rem" },
          }}
        >
          Contact Us
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
            lineHeight: 1.6,
            marginBottom: 2,
          }}
        >
          If you need any further assistance or have questions that aren’t
          covered here, our support team is here to help.
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            mb: 2,
            fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2rem" },
          }}
        >
          Phone Support
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
            lineHeight: 1.6,
            marginBottom: 2,
          }}
        >
          You can reach us at 0423 983 244.
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            mb: 2,
            fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2rem" },
          }}
        >
          Email Support
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
            lineHeight: 1.6,
            marginBottom: 2,
          }}
        >
          Send us an email at ali2200@gmail.com, and we’ll respond within 24
          hours.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
            lineHeight: 1.6,
          }}
        >
          Our team is here to support you every step of the way. Thank you for
          choosing us to create a beautiful memorial for your loved one.
        </Typography>
      </Box>{" "}
      <HomepageFooter />
    </>
  );
}

export default Support;
