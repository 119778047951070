import { useState, useCallback } from "react";
import axios from "axios";
import { useAuthContext } from "./useAuthContext";
import { useVideoContext } from "../Context/VideoContext"; // Adjust the path as needed

export const useGetAllVideos = () => {
  const { setVideoId } = useVideoContext();
  const { user } = useAuthContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const GetVideos = useCallback(async () => {
    if (!user) {
      alert("You must be logged in to get the videos");
      return null;
    }
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/video/getVideosByUserId`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setLoading(false);
      return response.data; // Return the data directly
    } catch (error) {
      setLoading(false);
      setError(error.response?.data || "Error fetching videos");
      return false;
    }
  }, [user]);

  const GetOrders = useCallback(async () => {
    if (!user) {
      alert("You must be logged in to get the videos");
      return null;
    }
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/video/getPaidVideoByUser`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setLoading(false);
      return response.data; // Return the data directly
    } catch (error) {
      setLoading(false);
      setError(error.response?.data || "Error fetching videos");
      return false;
    }
  }, [user]);

  const GetVideoRefresh = useCallback(
    async (videoId) => {
      if (!user) {
        alert("You must be logged in to get the videos");
        return null;
      }
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_DOMAIN}/api/video/RefreshVideo/${videoId}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setLoading(false);
        setVideoId(response.data.video); // Return the data directly
      } catch (error) {
        setLoading(false);
        setError(error.response?.data || "Error fetching videos");
        return false;
      }
    },
    [user, setVideoId]
  );

  return { GetVideos, GetVideoRefresh, GetOrders, loading, error };
};
