import "../../Styles/Loader.css";
import { CircularProgress, Box, Typography } from "@mui/material";
import React from "react";

function Loader({ text, text2, background }) {
  return (
    <Box className="Loader-spinner-overlay" sx={{ background: background }}>
      <Box className="Loader-circle-container">
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#62FFB4" />
              <stop offset="25%" stopColor="#62FFB4" />
              <stop offset="75%" stopColor="#8EB69B" />
              <stop offset="100%" stopColor="#DAF1DD" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          size={260} // Increase the size of the loader
          thickness={3}
          sx={{
            "svg circle": { stroke: "url(#my_gradient)" },
          }}
        />
        <Typography variant="h6" className="loading-text">
          {text}
          <Typography variant="h6">{text2}</Typography>
        </Typography>
      </Box>
    </Box>
  );
}

export default Loader;
