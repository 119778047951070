import { useAuthContext } from "./useAuthContext";

export const useLogout = () => {
  const { dispatch } = useAuthContext();

  const logout = async () => {
    // Remove from local storage
    localStorage.removeItem("user");
    // localStorage.removeItem("selectedImage");
    localStorage.removeItem("video_ID");
    localStorage.removeItem("backgroundImage");
    localStorage.removeItem("format");
    localStorage.removeItem("imageId");
    localStorage.removeItem("Song");
    // Dispatch logout action
    dispatch({ type: "LOGOUT" });
  };

  return { logout };
};
