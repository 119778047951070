import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  AppBar,
} from "@mui/material";
import { Link } from "react-router-dom";
import Dashboardnavbar from "../Dashboard/Dashboardnavbar";
import logo from "../../Images/logo1.png";

function MyProfile() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    // Retrieve the user data from local storage
    const user = localStorage.getItem("user"); // Adjust the key name if needed

    if (user) {
      // Parse the JSON string into an object
      const userData = JSON.parse(user);

      // Set the state with the user's information
      setFirstName(userData.firstName || "");
      setLastName(userData.lastName || "");
      setEmail(userData.email || "");
    }
  }, []); // Empty dependency array means this runs once after the initial render

  return (
    <>
      {/* ===================== navbar =====================*/}
      <AppBar position="static" sx={{ backgroundColor: "#000" }}>
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"space-between"}
          py={2}
        >
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Link to="/">
              <img src={logo} alt="logo" width="300px" />
            </Link>
          </Box>
          <Box>
            <Dashboardnavbar />
          </Box>
        </Box>
      </AppBar>
      {/*========================= main content ====================*/}
      <Box
        sx={{
          backgroundColor: "black",
          color: "white",
          minHeight: "80vh",
          display: "flex",
          // alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            padding: "20px",
          }}
        >
          <form
            style={{
              width: "100%",
              maxWidth: "400px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h4"
              component="p"
              sx={{
                alignSelf: "flex-start",
                textAlign: "left",
                mb: 8,
              }}
            >
              My Profile
            </Typography>
            <Typography
              variant="body1"
              component="label"
              htmlFor="name"
              sx={{ mb: 1, fontSize: "15px" }}
            >
              First name and Last name:
            </Typography>
            <Box display={"flex"} gap={2}>
              <TextField
                type="text"
                name="name"
                placeholder={firstName}
                required
                sx={{
                  mb: 2,
                  width: "50%",
                  border: "1px solid rgb(133, 133, 133)",
                  borderRadius: 1,
                  backgroundColor: "black",
                  color: "white",
                  fontSize: "10px",
                }}
                InputProps={{
                  style: {
                    color: "white",
                  },
                }}
              />
              <TextField
                type="text"
                name="name"
                placeholder={lastName}
                required
                sx={{
                  mb: 2,

                  border: "1px solid rgb(133, 133, 133)",
                  borderRadius: 1,
                  backgroundColor: "black",
                  color: "white",
                  fontSize: "10px",
                }}
                InputProps={{
                  style: {
                    color: "white",
                  },
                }}
              />
            </Box>
            <Typography
              variant="body1"
              component="label"
              htmlFor="email"
              sx={{ mb: 1, fontSize: "15px" }}
            >
              Email Address:
            </Typography>
            <TextField
              type="email"
              name="email"
              placeholder={email}
              required
              sx={{
                mb: 2,
                border: "1px solid rgb(133, 133, 133)",
                borderRadius: 1,
                backgroundColor: "black",
                color: "white",
                fontSize: "10px",
              }}
              InputProps={{
                style: {
                  color: "white",
                },
              }}
            />
            <Typography
              variant="body1"
              component="label"
              htmlFor="password"
              sx={{ mb: 1, fontSize: "15px" }}
            >
              Current Password:
            </Typography>
            <TextField
              type="password"
              name="password"
              placeholder="Password"
              required
              sx={{
                mb: 2,
                border: "1px solid rgb(133, 133, 133)",
                borderRadius: 1,
                backgroundColor: "black",
                color: "white",
                fontSize: "10px",
              }}
              InputProps={{
                style: {
                  color: "white",
                },
              }}
            />
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                <Button
                  variant="text"
                  sx={{
                    color: "rgba(98, 255, 180, 1)",
                    backgroundColor: "inherit",
                    border: "none",
                    fontSize: "small",
                  }}
                >
                  Delete Account
                </Button>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "rgba(98, 255, 180, 1)",
                    color: "black",
                    borderRadius: 1,
                    // p: 1,
                    fontSize: "small",
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default MyProfile;
