import React, { useEffect, useState, useRef } from "react";
import { Box, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "../../Styles/ImageCarousel.module.css";
import Loader from "../LoadingScreen/Loader";

const ImageCarousel = ({
  imageId,
  setSelectedImage,
  handleDeleteImage,
  deleteImageLoading,
  deleteImageError,
  handleSort,
  dragImage,
  draggedOverImage,
}) => {
  const scrollRef = useRef(null);
  const dragCloneRef = useRef(null);
  const dragTimeoutRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [draggedImageIndex, setDraggedImageIndex] = useState(null);
  const [images, setImages] = useState(imageId);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);

  useEffect(() => {
    setImages(imageId);
  }, [imageId]);

  const checkScrollPosition = () => {
    if (scrollRef.current) {
      setIsAtStart(scrollRef.current.scrollLeft === 0);
      const isEnd =
        scrollRef.current.scrollWidth <=
        scrollRef.current.scrollLeft + scrollRef.current.clientWidth + 1;
      setIsAtEnd(isEnd);
    }
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft -= 500;
      checkScrollPosition();
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += 500;
      checkScrollPosition();
    }
  };

  useEffect(() => {
    checkScrollPosition();
  }, []);

  const iconButtonStyle = {
    borderRadius: "50%",
    backgroundColor: "#62FFB4",
    "&:hover": {
      backgroundColor: "#62FFB4",
    },
  };

  const disabledButtonStyle = {
    borderRadius: "50%",
    backgroundColor: "lightgrey",
    "&:hover": {
      backgroundColor: "lightgrey",
    },
  };

  const handleDragStart = (index) => {
    dragImage.current = index;
    setDraggedImageIndex(index);
    setIsDragging(true);
  };

  const handleDragEnter = (e, index) => {
    e.preventDefault();
    draggedOverImage.current = index;
    const updatedImages = [...images];
    const draggedItem = updatedImages[dragImage.current];
    updatedImages.splice(dragImage.current, 1);
    updatedImages.splice(index, 0, draggedItem);
    dragImage.current = index;
    setDraggedOverIndex(index);
    setImages(updatedImages);

    // Auto-scroll when dragging near the edges
    if (scrollRef.current) {
      const { left, right } = scrollRef.current.getBoundingClientRect();
      const mouseX = e.clientX;

      if (mouseX < left + 50) {
        scrollRef.current.scrollLeft -= 30;
      } else if (mouseX > right - 50) {
        scrollRef.current.scrollLeft += 30;
      }
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    setDraggedImageIndex(null);
    setDraggedOverIndex(null);
    handleSort(images);
  };

  const moveAt = (pageX, pageY) => {
    if (dragCloneRef.current) {
      dragCloneRef.current.style.left =
        pageX - dragCloneRef.current.offsetWidth / 2 + "px";
      dragCloneRef.current.style.top =
        pageY - dragCloneRef.current.offsetHeight / 2 + "px";
    }
  };

  const handleTouchStart = (e, index, image) => {
    // console.log("start ", images);
    dragImage.current = index;
    setIsDragging(false);
    dragTimeoutRef.current = setTimeout(() => {
      setDraggedImageIndex(index);
      setIsDragging(true);
      const touch = e.touches[0];
      const clone = document.createElement("img");
      clone.src = image.url;
      clone.style.position = "absolute";
      clone.style.width = "123px";
      clone.style.height = "70px";
      clone.style.objectFit = "cover";
      clone.style.pointerEvents = "none";
      clone.style.zIndex = 1000;
      document.body.appendChild(clone);
      dragCloneRef.current = clone;
      moveAt(touch.pageX, touch.pageY);
    }, 200); // Adjust timeout for distinguishing touch from drag
  };

  const handleTouchMove = (e, index) => {
    if (isDragging && dragCloneRef.current) {
      const touch = e.touches[0];
      moveAt(touch.pageX, touch.pageY);
      const element = document.elementFromPoint(touch.clientX, touch.clientY);
      if (element && element.closest(".draggable-item")) {
        const overIndex = parseInt(
          element.closest(".draggable-item").dataset.index,
          10
        );

        if (draggedOverImage.current !== overIndex) {
          draggedOverImage.current = overIndex;
          const updatedImages = [...images];
          const draggedItem = updatedImages[dragImage.current];
          updatedImages.splice(dragImage.current, 1);
          updatedImages.splice(overIndex, 0, draggedItem);
          dragImage.current = overIndex;
          setDraggedOverIndex(overIndex);
          setImages(updatedImages);
          // console.log("after ", images);
        }
      }

      // Auto-scroll when dragging near the edges
      if (scrollRef.current) {
        const { left, right } = scrollRef.current.getBoundingClientRect();
        if (touch.clientX < left + 50) {
          scrollRef.current.scrollLeft -= 20;
        } else if (touch.clientX > right - 50) {
          scrollRef.current.scrollLeft += 20;
        }
      }
    }
  };

  const handleTouchEnd = () => {
    clearTimeout(dragTimeoutRef.current);
    if (isDragging) {
      setIsDragging(false);
      if (dragCloneRef.current) {
        document.body.removeChild(dragCloneRef.current);
        dragCloneRef.current = null;
      }
      handleSort(images);
      setDraggedOverIndex(null);
      setDraggedImageIndex(null);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        position: "relative",
      }}
    >
      {!(isAtStart && isAtEnd) && (
        <IconButton
          onClick={scrollLeft}
          sx={{
            position: "absolute",
            left: "6%",
            bottom: "35px",
            zIndex: "9998",
            ...iconButtonStyle,
            ...(isAtStart && disabledButtonStyle),
          }}
          size="small"
        >
          <ArrowBackIosNewIcon
            sx={{ color: isAtStart ? "dark grey" : "black" }}
          />
        </IconButton>
      )}
      <Box
        ref={scrollRef}
        onScroll={checkScrollPosition}
        // onTouchMove={handleWheel} // Disable touch scrolling
        // onWheel={handleWheel} // Disable mouse wheel scrolling
        className={styles.carouselContainer}
        sx={{
          display: "flex",
          overflowX: "scroll", // Keep native scrollbar visible
          overflowY: "hidden", // Keep native scrollbar visible
          justifyContent: "flex-start",
          flex: 1,
          touchAction: "none", // Disable touch scrolling
        }}
      >
        {images &&
          images.map((image, index) => (
            <div
              key={index}
              className="draggable-item"
              data-index={index}
              draggable
              onDragStart={() => handleDragStart(index)}
              onDragEnter={(e) => handleDragEnter(e, index)}
              onDragOver={(e) => e.preventDefault()}
              onDragEnd={handleDragEnd}
              onTouchStart={(e) => handleTouchStart(e, index, image)}
              onTouchMove={(e) => handleTouchMove(e, index)}
              onTouchEnd={handleTouchEnd}
              style={{
                opacity: draggedImageIndex === index ? 0 : 1,
                transition: "transform 0.5s ease, opacity 0.4s ease",
                transform:
                  draggedImageIndex === index ? "scale(1.1)" : "scale(1)",
              }} // Change opacity of the dragged item
            >
              <Box
                onClick={() => setSelectedImage(image)}
                sx={{
                  position: "relative",
                  "&:hover .delete-icon": { display: "flex" },
                  mb: 1,
                }}
              >
                <img
                  src={image.url}
                  alt={`carousel-${index}`}
                  style={{
                    width: "123px",
                    height: "70px",
                    objectFit: "cover",
                    cursor: "pointer",
                    margin: "0 10px",
                    border:
                      draggedOverIndex === index
                        ? "2px solid #62FFB4"
                        : "2px solid #181C1F",
                  }}
                  onContextMenu={(e) => e.preventDefault()} // Prevent default context menu
                />
                <IconButton
                  onClick={() => handleDeleteImage(image.id)}
                  className="delete-icon"
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: "5px",
                    color: "white",
                    backgroundColor: "transparent",
                    display: "none",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  {deleteImageLoading ? (
                    <Loader text="Deleting" />
                  ) : (
                    <DeleteIcon />
                  )}
                </IconButton>
              </Box>
              {deleteImageError && (
                <Box sx={{ color: "red", mt: 1 }}>
                  {deleteImageError.message}
                </Box>
              )}
            </div>
          ))}
      </Box>
      {!(isAtStart && isAtEnd) && (
        <IconButton
          onClick={scrollRight}
          sx={{
            ...iconButtonStyle,
            position: "absolute",
            right: "6%",
            bottom: "35px",
            ...(isAtEnd && disabledButtonStyle),
          }}
          size="small"
        >
          <ArrowForwardIosIcon
            sx={{ color: isAtEnd ? "dark grey" : "black" }}
          />
        </IconButton>
      )}
    </Box>
  );
};

export default ImageCarousel;
