import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#fff",
            },
            "&:hover fieldset": {
              borderColor: "#fff",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#fff",
            },
            "& input": {
              color: "#fff",
            },
          },
          "& .MuiInputLabel-root": {
            color: "#fff",
          },
        },
      },
    },
  },
  palette: {
    // Move palette here
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#62FFB4",
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
});

export default theme;
