import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Alert,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import { useVideoContext } from "../../../Context/VideoContext"; // Adjust the path as needed
import { useBackgroundContext } from "../../../Context/BackgroundContext"; // Adjust the path as needed
import { useAuthContext } from "../../../Hooks/useAuthContext";
import { useGetAllBackgrounds } from "../../../Hooks/useGetBackgrounds";
import { useGetAllVideos } from "../../../Hooks/useGetAllVideos";
import { PopupWindowContext } from "../../../Context/PopupWindowContext";
import styles from "../../../Styles/ImageCarousel.module.css";

function HorizontalBackground({ component, handleClose }) {
  const { GetBackgrounds, loading, error } = useGetAllBackgrounds();

  const { GetVideoRefresh } = useGetAllVideos();
  const { user } = useAuthContext();
  const { videoId } = useVideoContext();
  const { backgroundImage } = useBackgroundContext();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLeftDisabled, setIsLeftDisabled] = useState(true);
  const [isRightDisabled, setIsRightDisabled] = useState(false);
  const scrollRef = useRef(null);

  const { setFormState } = useContext(PopupWindowContext);

  useEffect(() => {
    if (!backgroundImage) {
      GetBackgrounds();
    }
  }, [GetBackgrounds, backgroundImage]);

  const handleBackgroundSelect = (index, image) => {
    setSelectedImage(index);
    setFormState((prevState) => ({
      ...prevState,
      Background: image.BackgroundUrl,
    }));
  };

  const handleSelectImage = (index, img) => {
    setSelectedImage(index);
    handleDoubleClick(img);
    setTimeout(() => {
      handleClose();
    }, 500);
  };

  const handleDoubleClick = async (image) => {
    if (!user) {
      alert("You must be logged in to update the image");
      return;
    }

    try {
      await axios.patch(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/video/${videoId._id}/bg-image`,
        {
          backgroundImage: image.BackgroundUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      GetVideoRefresh(videoId._id);
      console.log("Image updated successfully");
    } catch (error) {
      console.error("Error updating image:", error);
      alert("Failed to update image");
    }
  };

  const updateScrollButtons = () => {
    if (scrollRef.current) {
      setIsLeftDisabled(scrollRef.current.scrollLeft <= 0);
      setIsRightDisabled(
        scrollRef.current.scrollLeft + scrollRef.current.clientWidth >=
          scrollRef.current.scrollWidth
      );
    }
  };

  useEffect(() => {
    updateScrollButtons();
  }, []);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft -= 500;
      updateScrollButtons();
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += 500;
      updateScrollButtons();
    }
  };

  const iconButtonStyle = {
    borderRadius: "50%",
    backgroundColor: "#62FFB4",
    margin: "5px 3px 10px 3px",
    "&:hover": {
      backgroundColor: "#62FFB4", // Maintain the same background color on hover
    },
  };

  const disabledIconButtonStyle = {
    ...iconButtonStyle,
    backgroundColor: "black",
    cursor: "not-allowed",
  };

  return (
    <Box
      height="300px"
      sx={{
        width: {
          xs: component === "wizard" ? "80vw" : "94vw",
          md: "60vw",
          xl: "40vw",
        },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        width={component === "wizard" ? "100%" : { xs: "100%", md: "80%" }}
        display="flex"
        justifyContent="space-between"
        mb={2}
      >
        <Typography
          alignContent="center"
          variant="body2"
          sx={{
            opacity: "70%",
            lineHeight: "21px",
            fontWeight: "500",
            fontSize: {
              xs: "0.6rem",
              sm: "0.7rem",
              md: "0.7rem",
              lg: "0.8rem",
              xl: "0.9rem",
            },
            pl: {
              xs: "10px",
              lg: "0px",
            },
          }}
        >
          {component === "wizard"
            ? "Recommended backgrounds ready to use."
            : ""}
        </Typography>
        <Box
          sx={{
            pr: {
              xs: "10px",
              lg: "0px",
            },
          }}
        >
          <IconButton
            onClick={scrollLeft}
            style={isLeftDisabled ? disabledIconButtonStyle : iconButtonStyle}
            size="small"
            disabled={isLeftDisabled}
          >
            <ArrowBackIosNewIcon
              sx={{
                fontSize: "14px",
                color: isLeftDisabled ? "grey" : "black",
              }}
            />
          </IconButton>
          <IconButton
            onClick={scrollRight}
            style={isRightDisabled ? disabledIconButtonStyle : iconButtonStyle}
            size="small"
            disabled={isRightDisabled}
          >
            <ArrowForwardIosIcon
              sx={{
                fontSize: "14px",
                color: isRightDisabled ? "grey" : "black",
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box
        className={styles.carouselContainer}
        ref={scrollRef}
        onScroll={updateScrollButtons}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          overflowY: "hidden",
          overflowX: "auto",
          maxWidth: component === "wizard" ? "100%" : { xs: "95%", md: "80%" },
          // paddingX: {
          //   xs: "0px",
          //   lg: "0px",
          // },
          padding: "0px 0px 10px 0px",
        }}
      >
        {loading && (
          <Box display="flex" justifyContent="center" width="100vw">
            <CircularProgress />
          </Box>
        )}
        {error && (
          <Box display="flex" justifyContent="center" width="100%">
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        {!loading &&
          !error &&
          backgroundImage &&
          backgroundImage.map((img, index) => (
            <Box
              key={index}
              sx={{
                position: "relative",
                cursor: "pointer",
                borderRadius: "3px",
                marginRight: "16px",
                height: component === "wizard" ? "184px" : "150px",
                width: component === "wizard" ? "188px" : "150px",
                transition: "transform 0.3s",
                border: selectedImage === index ? "3px solid #62FFB4" : "none",
              }}
              onClick={() => {
                component === "wizard"
                  ? handleBackgroundSelect(index, img)
                  : handleSelectImage(index, img);
              }}
            >
              <img
                src={img.BackgroundUrl}
                alt={img.BackgroundName}
                style={{
                  width: component === "wizard" ? "187px" : "150px",
                  height: component === "wizard" ? "187px" : "150px",
                  objectFit: "cover",
                }}
              />
              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  position: "absolute",
                  bottom: component === "wizard" ? "10%" : "15%",
                  left: "10%",
                  backgroundColor: "rgba(255, 255, 255, 0.74)",
                  color: "#000000",
                  padding: "0px 10px",
                  borderRadius: "12px",
                  textAlign: "center",
                }}
              >
                {img.BackgroundName}
              </Typography>
            </Box>
          ))}
      </Box>
    </Box>
  );
}

export default HorizontalBackground;
