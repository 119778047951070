import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Grid, Box, IconButton, Divider } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";
import { useVideoContext } from "../../Context/VideoContext"; // Adjust the path as needed
import { useImageContext } from "../../Context/ImageContext"; // Adjust the path as needed
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useRenameVideo } from "../../Hooks/useRenameVideo";
import { useRorderImages } from "../../Hooks/useReorderImages";
import useDeleteImage from "../../Hooks/useDeleteImage"; // Adjust the path as needed

import "../../Styles/EditScreen.css";
import logo from "../../Images/logo.png";
import Loader from "../LoadingScreen/Loader";
import { useGetAllImages } from "../../Hooks/useGetImages";
import useSocket from "../../Hooks/useSocket"; // Import the custom hook

import ImageCarousel from "./ImageCarousel";
import Navbar from "./Navbar";
import NavbarMobile from "./NavbarMobile";
import MainEditWindow from "./MainEditWindow";

function EditScreen() {
  //context
  const { videoId } = useVideoContext();
  const { imageId, setImageId } = useImageContext();
  const { user } = useAuthContext();
  const { deleteImage, deleteImageLoading, deleteImageError } =
    useDeleteImage();
  const location = useLocation();
  const { previewActive } = location.state || { previewActive: false };
  //hooks
  const { RenameVideo } = useRenameVideo();
  const scrollRef = useRef(null);
  const [isRenaming, setIsRenaming] = useState(false);
  const [videoName, setVideoName] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { GetImages } = useGetAllImages();
  const { RorderImages } = useRorderImages();
  const [showDropzone, setShowDropzone] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState(false);
  const navigate = useNavigate();
  const dragImage = useRef(0);
  const draggedOverImage = useRef(0);

  // WebSocket setup
  const { socket, progress } = useSocket(process.env.REACT_APP_SERVER_DOMAIN);
  console.log("socket id : ", socket);

  function handleSort(updatedImages) {
    RorderImages(updatedImages);
    setImageId(updatedImages);
  }

  useEffect(() => {
    if (videoId.status === "Finished") {
      navigate("/dashboard");
    }
    // Select only the first image when imageId changes
    if (imageId.length > 0) {
      setSelectedImage(imageId[0]);
    }
    setVideoName(videoId.title);
  }, [imageId, navigate, videoId]);

  const handleDropzoneClose = () => {
    setShowDropzone(false);
  };
  const handleVideoClose = () => {
    setShowVideo(false);
  };
  const handleDeleteImage = async (url) => {
    try {
      await deleteImage(url);
      GetImages();
      setSelectedImage(null);
    } catch (error) {
      console.error("Failed to delete image", error);
    }
  };

  const handleExportVideo = async () => {
    if (!user) {
      alert("You must be logged in to export the video");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/test/${videoId._id}/${socket}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setVideoUrl(response.data.videoUrl);
    } catch (error) {
      console.error("Error exporting video:", error);
      alert("Failed to export video");
    } finally {
      setLoading(false);
      setShowVideo(true);
    }
  };

  const handleRenameClick = () => {
    setIsRenaming(true);
  };

  const handleSaveClick = () => {
    setIsRenaming(false);
    RenameVideo(videoName);
  };

  return (
    <Grid container className="EditScreen-container">
      {loading && (
        <Loader
          text={`Generating Video `}
          text2={` ${progress || 0}%`}
          backround="rgba(0, 0, 0, 0.3)"
        />
      )}
      {/* Main Content */}
      <Grid
        item
        xs={12}
        md={9.5}
        lg={9.7}
        display="flex"
        flexDirection="column"
        sx={{ height: "100vh", overflow: "hidden" }}
      >
        {/* Navbar */}
        <Navbar
          logo={logo}
          isRenaming={isRenaming}
          videoName={videoName}
          setVideoName={setVideoName}
          handleSaveClick={handleSaveClick}
          handleRenameClick={handleRenameClick}
        />
        <NavbarMobile
          logo={logo}
          isRenaming={isRenaming}
          videoName={videoName}
          setVideoName={setVideoName}
          handleSaveClick={handleSaveClick}
          handleRenameClick={handleRenameClick}
        />

        {/* Main Edit Window */}
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ maxHeight: { xs: "75%", lg: "72%" }, position: "relative" }}
        >
          <MainEditWindow
            previewActive={previewActive}
            videoId={videoId}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            showDropzone={showDropzone}
            handleDropzoneClose={handleDropzoneClose}
            showVideo={showVideo}
            handleVideoClose={handleVideoClose}
            videoUrl={videoUrl}
          />
        </Grid>

        {/* Carousel */}
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          sx={{
            maxHeight: { xs: "20%", lg: "20%", xl: "15" },
            backgroundColor: "#181C1F",
          }}
        >
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: { xs: "center", sm: "center" },
              alignContent: { xs: "flex-start", sm: "center" },
              gap: { xs: 0, sm: 1 },
            }}
          >
            <IconButton onClick={handleExportVideo}>
              <PlayArrowIcon
                sx={{
                  color: "black",
                  fontSize: 40,
                  padding: "4px",
                  borderRadius: 50,
                  backgroundColor: "#62FFB4",
                  "&:hover": {
                    backgroundColor: "#62FFB4", // Maintain the same background color on hover
                  },
                }}
              />
            </IconButton>
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                display: { xs: "none", lg: "flex" },
                backgroundColor: "grey",
                mt: 3,
                height: "20px", // Set your desired height here
              }}
            />
            <IconButton onClick={() => setShowDropzone(true)}>
              <AddIcon
                sx={{
                  color: "white",
                  fontSize: 30,
                  border: "2px solid white",
                  borderRadius: 50,
                }}
              />
            </IconButton>
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                display: { xs: "none", lg: "flex" },
                backgroundColor: "grey",
                mt: 3,
                height: "20px", // Set your desired height here
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: "100%",
                position: "relative",
              }}
            >
              <ImageCarousel
                imageId={imageId}
                scrollRef={scrollRef}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                handleDeleteImage={handleDeleteImage}
                deleteImageLoading={deleteImageLoading}
                deleteImageError={deleteImageError}
                handleSort={handleSort}
                dragImage={dragImage}
                draggedOverImage={draggedOverImage}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/* Sidebar */}
      <Sidebar
        videoId={videoId}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
      />
    </Grid>
  );
}

export default EditScreen;
