import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import image from "../../Images/Intropage/pages/mail.png";
function PageFour() {
  return (
    <Box
      sx={{
        backgroundColor: "black",
        minHeight: { xs: "60vh", md: "60vh" },
        padding: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: { xs: "20px", md: "30px" } }}
      >
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            color: "white",
            textAlign: { xs: "center", md: "left" },
            padding: { xs: 2, md: 4 },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              marginBottom: "10px",
              width: { xs: "80%", md: "80%" },
              margin: "20px auto",
            }}
          >
            <Typography
              sx={{
                width: { xs: "100%", md: "70%" },

                textAlign: { xs: "center", md: "left" },
                lineHeight: { xs: "35px", md: "40px" },
                fontWeight: 600,
                fontSize: { xs: "2rem", sm: "2rem", md: "2.5rem" },
              }}
            >
              Receive your final video via email !
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            sx={{
              lineHeight: "normal",
              marginBottom: "20px",
              textAlign: { xs: "center", md: "left" },
              width: { xs: "80%", md: "80%" },
              margin: "5px auto",
              fontSize: { xs: "0.9rem", sm: "1rem", md: "1.2rem" },
            }}
          >
            After customizing your memorial video, simply purchase it and
            receive the final product via email.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
        >
          <Box
            sx={{
              width: { xs: "400px", md: "550px" },
              height: "auto",
              color: "white",
            }}
          >
            <img
              src={image}
              alt="Mail Icon" // Alt text for the image
              style={{
                width: "100%", // Make the image take up full width
                height: "100%", // Make the image take up full height (you can adjust this as necessary)
                objectFit: "contain", // Optional: Ensures the image maintains its aspect ratio
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PageFour;
