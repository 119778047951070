import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import SignupPage from "./Components/LoginScreen/SignupPage";
import LoginPage from "./Components/LoginScreen/LoginPage";
import IntroPage from "./Components/IntroScreen/IntroPage";
import Dashboard from "./Components/Dashboard/Dashboard";
import EditScreen from "./Components/EditScreen/EditScreen";
import { useAuthContext } from "./Hooks/useAuthContext";
import Loader from "./Components/LoadingScreen/Loader";
import Checkout from "./Components/Payment/Checkout";
import SuccessPayment from "./Components/Payment/SuccessPayment";
import CancelPayment from "./Components/Payment/CancelPayment";
import ContactPage from "./Components/Contact/ContactPage";
import MyProfile from "./Components/Profile/MyProfile";
import Pricing from "./Components/IntroScreen/Pricing";
import Support from "./Components/IntroScreen/Support";
import FeaturesIntroPage from "./Components/IntroScreen/FeaturesIntroPage";

const App = () => {
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 400); // delay in milliseconds (1 second in this case)

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <Loader text="LOADING..." background="black" />; // you can add a loading spinner here
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={!user ? <IntroPage /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/signup"
          element={!user ? <SignupPage /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/login"
          element={!user ? <LoginPage /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/pricing"
          element={!user ? <Pricing /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/support"
          element={!user ? <Support /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/features"
          element={!user ? <FeaturesIntroPage /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/edit"
          element={user ? <EditScreen /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard"
          element={user ? <Dashboard /> : <Navigate to="/" />}
        />
        <Route
          path="/checkout"
          element={user ? <Checkout /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/success"
          element={user ? <SuccessPayment /> : <Navigate to="/checkout" />}
        />
        <Route
          path="/cancel"
          element={user ? <CancelPayment /> : <Navigate to="/checkout" />}
        />
        <Route
          path="/contact"
          element={user ? <ContactPage /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/account"
          element={user ? <MyProfile /> : <Navigate to="/dashboard" />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
