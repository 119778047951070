import React, { useState } from "react";
import {
  Box,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Button,
  Link,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import mainlogo from "../../Images/logo1.png";
import mobilelogo from "../../Images/logo.png";
import { Link as RouterLink } from "react-router-dom";

function Navbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <Toolbar
      sx={{ display: "flex", justifyContent: "space-between", paddingX: 1 }}
    >
      {/* Logo */}
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <RouterLink to="/">
          <img
            src={mobilelogo}
            alt="Mobile Logo"
            style={{ maxWidth: "50px", padding: "2px", cursor: "pointer" }}
          />
        </RouterLink>
      </Box>

      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <RouterLink to="/">
          <img
            src={mainlogo}
            alt="Logo"
            style={{ maxWidth: "230px", padding: "3px", cursor: "pointer" }}
          />
        </RouterLink>
      </Box>

      {/* Desktop Links */}
      <Box
        component="nav"
        sx={{
          display: { xs: "none", md: "flex" },
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <Link component={RouterLink} to="/features" sx={linkStyle}>
          Features
        </Link>
        <Link component={RouterLink} to="/pricing" sx={linkStyle}>
          Pricing
        </Link>
        <Link component={RouterLink} to="/support" sx={linkStyle}>
          Support
        </Link>
      </Box>

      {/* Hamburger Menu for Mobile */}
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <IconButton color="secondary" onClick={toggleDrawer(true)}>
          <MenuIcon fontSize="large" />
        </IconButton>
      </Box>

      {/* Right Drawer for Mobile Menu */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 250,
            backgroundColor: "black",
            height: "100%",
            color: "white",
            position: "relative",
          }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          {/* Close Button in Drawer */}
          <IconButton
            sx={{ position: "absolute", top: 10, right: 10, color: "white" }}
            onClick={toggleDrawer(false)}
          >
            <CloseIcon />
          </IconButton>

          <List sx={{ marginTop: 4 }}>
            <ListItem
              button
              component={RouterLink}
              to="/features"
              sx={{ marginBottom: 1 }}
            >
              <ListItemText primary="Features" sx={listItemStyle} />
            </ListItem>
            <ListItem
              button
              component={RouterLink}
              to="/pricing"
              sx={{ marginBottom: 1 }}
            >
              <ListItemText primary="Pricing" sx={listItemStyle} />
            </ListItem>
            <ListItem
              button
              component={RouterLink}
              to="/support"
              sx={{ marginBottom: 1 }}
            >
              <ListItemText primary="Support" sx={listItemStyle} />
            </ListItem>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              gap={1}
            >
              <Button
                component={RouterLink}
                to="/login"
                variant="outlined" // Adds an outline border
                sx={{
                  ...buttonStyle,
                  width: "fit-content",
                  color: "white", // Adjust text color if needed
                  borderColor: "white", // Customize border color
                  "&:hover": {
                    borderColor: "white", // Keep border color on hover
                    backgroundColor: "rgba(255, 255, 255, 0.1)", // Optional background on hover
                  },
                }}
              >
                Sign In
              </Button>

              <Button
                component={RouterLink}
                to="/signup"
                sx={{
                  ...buttonStyle,
                  width: "fit-content",
                  backgroundColor: "#62FFB4",
                  color: "black",
                  "&:hover": { backgroundColor: "#62FFB4" },
                }}
              >
                Get Started
              </Button>
            </Box>

            {/* <ListItem
              button
              component={RouterLink}
              to="/login"
              sx={{
                ...listItemStyle,
                backgroundColor: "#3F806F",
                "&:hover": { backgroundColor: "#4FBF8D" },
                marginBottom: 1,
              }}
            >
              <ListItemText primary="Sign In" sx={listItemStyle} />
            </ListItem>
            <ListItem
              button
              component={RouterLink}
              to="/signup"
              sx={{
                ...listItemStyle,
                backgroundColor: "#3F806F",
                "&:hover": { backgroundColor: "#4FBF8D" },
                marginBottom: 1,
              }}
            >
              <ListItemText primary="Get Started" sx={listItemStyle} />
            </ListItem> */}
          </List>
        </Box>
      </Drawer>

      {/* Sign In and Get Started Buttons (Desktop) */}
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <Button component={RouterLink} to="/login" sx={buttonStyle}>
          Sign In
        </Button>
        <Button
          component={RouterLink}
          to="/signup"
          sx={{
            ...buttonStyle,
            backgroundColor: "#62FFB4",
            color: "black",
            "&:hover": { backgroundColor: "#62FFB4" },
          }}
        >
          Get Started
        </Button>
      </Box>
    </Toolbar>
  );
}

const linkStyle = {
  margin: "10px",
  fontFamily: "poppins",
  textDecoration: "none",
  color: "white",
  fontSize: "1rem",
  "&:hover": { color: "white" },
};

const listItemStyle = {
  fontFamily: "poppins",
  textDecoration: "none",
  color: "white",
  fontSize: "1rem",
};

const buttonStyle = {
  margin: "5px",
  borderRadius: "10px",
  fontSize: "0.9rem",
  padding: "4px 12px",
  color: "white",
  textDecoration: "none",
  "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" },
};

export default Navbar;
