import React, { useState } from "react";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import "../../../../Styles/PreviewStep.css";
import { useNavigate } from "react-router-dom";
const steps = [
  {
    title: "Select image to reorder",
    content:
      "Click here to change image and click & drag horizontally to reorder images.",
  },
  {
    title: "Change image",
    content: "Click here to change image and upload desired image.",
  },
  {
    title: "Add new image block",
    content: "Click here to add new image block and upload desired image.",
  },
  {
    title: "Preview video",
    content:
      "Click here to preview a 1 minute watermarked version of the video.",
  },
  {
    title: "Export final video",
    content:
      "Click here to get your final watermark removed version of the video via email.",
  },
];

const PreviewStep = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      navigate("/edit");
    }
  };

  const handleSkip = () => {
    navigate("/edit");
  };
  const getClipPathClass = () => {
    switch (currentStep) {
      case 1:
        return "clipPath-right";
      case 4:
        return "clipPath-up";
      case 3:
        return "clipPath-bottom-left";
      default:
        return "clipPath-bottom";
    }
  };

  const getPositionClass = () => {
    switch (currentStep) {
      case 0:
        return "position-bottom-center";
      case 1:
        return isMobileScreen
          ? "position-top-right-mobile"
          : isTabletScreen
          ? "position-top-right-mobile"
          : "position-top-right";
      case 2:
        return isMobileScreen
          ? "position-bottom-left-mobile "
          : "position-bottom-left";
      case 3:
        return isMobileScreen ? "preview-video-mobile" : "preview-video";
      case 4:
        return "position-topest-right";
      default:
        return "position-center";
    }
  };

  return (
    <Grid
      container
      sx={{
        height: {
          xs: "150px", // Extra-small screens
          sm: "200px", // Small screens
          md: "188px", // Medium screens
          lg: "188px", // Large screens
        },
        width: {
          xs: "280px", // Extra-small screens
          sm: "300px", // Small screens
          md: "300px", // Medium screens
          lg: "322px", // Large screens
        },
      }}
      className={`stepContainer ${getClipPathClass()} ${getPositionClass()}`}
      // className={`stepContainer ${getPositionClass()}`}
    >
      <Grid
        item
        xs={9}
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
      >
        <Typography
          variant="h6"
          color="black"
          sx={{
            fontSize: {
              xs: "0.7rem",
              sm: "0.8rem",
              md: "0.9rem",
            },
            zIndex: " 99999",

            lineHeight: "17.28px",
          }}
        >
          {steps[currentStep].title}
        </Typography>
        <Typography
          variant="body1"
          color="black"
          sx={{
            fontSize: {
              xs: "0.7rem",
              sm: "0.8rem",
              md: "0.9rem",
            },
            lineHeight: "17.28px",
          }}
          className="bodyText"
        >
          {steps[currentStep].content}
        </Typography>
        <Box
          width={"100%"}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="stepNumber"
            color="white"
            width={"30px"}
            height={"8px"}
            sx={{
              fontSize: {
                xs: "0.7rem",
                sm: "0.7rem",
                md: "0.8rem",
              },
            }}
          >
            {currentStep + 1}/{steps.length}
          </Typography>
        </Box>
      </Grid>

      <Grid
        item
        xs={3}
        display="flex"
        flexDirection="column"
        alignItems={"center"}
        justifyContent="space-between"
        pb={1}
        pl={1}
      >
        {currentStep < steps.length - 1 && (
          <Button
            size="small"
            variant="outlined"
            className="skipButton"
            sx={{
              borderColor: "black",
              color: "black",
              fontSize: {
                xs: "0.7rem",
                sm: "0.8rem",
                md: "0.8rem",
              },
              "&:hover": { borderColor: "black", color: "#181C1F" },
            }}
            onClick={handleSkip}
          >
            Skip
          </Button>
        )}
        {currentStep < steps.length - 1 ? (
          <Button
            variant="contained"
            size="small"
            className="nextButton"
            sx={{
              color: "#62FFB4",
              backgroundColor: "#181C1F",
              fontSize: {
                xs: "0.7rem",
                sm: "0.8rem",
                md: "0.9rem",
              },
              "&:hover": { backgroundColor: "#181C1F" },
            }}
            onClick={handleNext}
          >
            Next
          </Button>
        ) : (
          <Typography
            variant="h6"
            className="goToEditor"
            color={"black"}
            onClick={handleNext}
            lineHeight={"17.28px"}
            mb={1}
            sx={{
              fontWeight: "600",
              fontSize: {
                xs: "0.7rem",
                sm: "0.8rem",
                md: "0.9rem",
              },
            }}
          >
            Get Started Now
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default PreviewStep;
