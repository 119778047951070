import React, { useState, useEffect, useRef } from "react";

import PreviewStep from "./Wizard/Preview/PreviewStep";
import Dropzone from "./Dropzone";
import VideoPlayer from "./Videplayer/VideoPlayer";
import { useFormatContext } from "../../Context/FormatContext"; // Adjust the path as needed
import { Box, IconButton, Paper, Collapse } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Sidebar from "./Sidebar/Sidebar"; // Adjust this import based on your actual Sidebar component location
import VideoEditing from "./VideoEditing"; // Adjust this import based on your actual VideoEditing component location

const MainEditWindow = ({
  previewActive,
  videoId,
  selectedImage,
  showDropzone,
  handleDropzoneClose,
  showVideo,
  handleVideoClose,
  videoUrl,
  setSelectedImage,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [videoEditingOpen, setVideoEditingOpen] = useState(false);
  const { format } = useFormatContext();

  const handleEditClick = () => {
    setVideoEditingOpen(false); // Close video editing if menu is opened
    setSidebarOpen((prevOpen) => !prevOpen);
  };

  const handleMenuClick = () => {
    setSidebarOpen(false); // Close sidebar if video editing is opened
    setVideoEditingOpen((prevOpen) => !prevOpen);
  };
  const sidebarRef = useRef(null);
  const videoEditingRef = useRef(null);
  const editButtonRef = useRef(null);
  const menuButtonRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target) &&
      !menuButtonRef.current.contains(event.target)
    ) {
      setSidebarOpen(false);
    }
    if (
      videoEditingRef.current &&
      !videoEditingRef.current.contains(event.target) &&
      !editButtonRef.current.contains(event.target)
    ) {
      setVideoEditingOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const backgroundStyles = {
    "9:16": {
      width: { xs: "70vw", sm: "262px", md: "262px", lg: "262px" },
      height: { xs: "60vh", sm: "346px", md: "346px", lg: "346px" },
    },
    "16:9": {
      width: { xs: "270px", sm: "509px", md: "609px", lg: "629px" },
      height: { xs: "188px", sm: "300px", md: "320px", lg: "334px" },
    },
    "1:1": {
      width: { xs: "270px", sm: "420px", md: "420px", lg: "420px" },
      height: { xs: "224px", sm: "350px", md: "350px", lg: "350px" },
    },
  };

  const imageStyles = {
    "9:16": {
      width: { xs: "70vw", sm: "262px", md: "262px", lg: "262px" },
      height: { xs: "29vh", sm: "176px", md: "176px", lg: "176px" },
    },
    "16:9": {
      width: { xs: "236px", sm: "400px", md: "503px", lg: "520px" },
      height: { xs: "142px", sm: "226px", md: "240px", lg: "266px" },
    },
    "1:1": {
      width: { xs: "220px", sm: "350px", md: "350px", lg: "350px" },
      height: { xs: "178px", sm: "280px", md: "280px", lg: "280px" },
    },
  };

  const selectedFormat = format.length > 0 ? format : "16:9";
  const backgroundStyle = backgroundStyles[selectedFormat];
  const selectedImageStyle = imageStyles[selectedFormat];
  const responsiveStyle = (style) => {
    return {
      width: "90%",
      height: "90%",
      ...style,
    };
  };

  return (
    <>
      {previewActive && <PreviewStep />}
      {videoId && (
        <Box
          sx={{
            position: "absolute",
            ...responsiveStyle(backgroundStyle),
          }}
        >
          <img
            src={videoId.backgroundImage}
            alt="Background"
            style={{
              position: "relative",
              objectFit: "cover",
              width: "100%",
              height: "100%",
              zIndex: 1,
            }}
          />
        </Box>
      )}

      {selectedImage && (
        <Box
          sx={{
            position: "absolute",
            ...responsiveStyle(selectedImageStyle),
          }}
        >
          <img
            src={selectedImage.url}
            alt="Selected"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: 2,
            }}
          />
        </Box>
      )}
      {showDropzone && <Dropzone onClose={handleDropzoneClose} />}
      {showVideo && (
        <VideoPlayer onClose={handleVideoClose} videoSrc={videoUrl} />
      )}
      <Box
        sx={{
          position: "absolute",
          top: "28%",
          right: "0",
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
        }}
      >
        <VideoEditing />
        <IconButton
          ref={menuButtonRef}
          onClick={handleEditClick}
          sx={{
            color: "#00FF9D",
            zIndex: 1201,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <EditIcon
            sx={{
              fontSize: { xs: 30, sm: 35, md: 20, lg: 25 },
              mr: { xs: "1px", sm: "4px", md: "4px", lg: "3px" },
              mt: 6,
            }}
          />
        </IconButton>
      </Box>
      <Collapse in={sidebarOpen} unmountOnExit>
        <Paper
          ref={sidebarRef}
          elevation={3}
          sx={{
            position: "absolute",
            top: "50px",
            right: 0,
            zIndex: 1201,
            width: "100vw",
            maxWidth: "250px",
          }}
        >
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <Sidebar
              videoId={videoId}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
            />
          </Box>
        </Paper>
      </Collapse>
    </>
  );
};

export default MainEditWindow;
