import { useAuthContext } from "./useAuthContext";
import axios from "axios";
import { useCallback } from "react";
// import { useVideoContext } from "../Context/VideoContext";
import { useSongContext } from "../Context/SongContext";

export const useGetAllSongs = () => {
  const { user } = useAuthContext();
  //   const { videoId } = useVideoContext();
  const { setSong } = useSongContext();

  const GetSongs = useCallback(async () => {
    if (!user) {
      alert("You must be logged in to get the Songs");
      return null;
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/song`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      //   console.log("getSong hook", response.data);

      setSong(response.data);
      // console.log("new Songs: ", SongId);
    } catch (error) {
      console.error("Error fetching Songs:", error);

      return false;
    }
  }, [user, setSong]);

  return { GetSongs };
};
