import React, { createContext, useState, useEffect, useContext } from "react";

// Background Context
const BackgroundContext = createContext();

export const useBackgroundContext = () => useContext(BackgroundContext);

export const BackgroundContextProvider = ({ children }) => {
  const [backgroundImage, setBackgroundImage] = useState(null);

  useEffect(() => {
    const storedBackgroundImage =
      window.localStorage.getItem("backgroundImage");
    if (storedBackgroundImage) {
      setBackgroundImage(JSON.parse(storedBackgroundImage));
    }
  }, []);

  useEffect(() => {
    if (backgroundImage !== null) {
      window.localStorage.setItem(
        "backgroundImage",
        JSON.stringify(backgroundImage)
      );
    }
  }, [backgroundImage]);

  return (
    <BackgroundContext.Provider value={{ backgroundImage, setBackgroundImage }}>
      {children}
    </BackgroundContext.Provider>
  );
};
