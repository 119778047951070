import React, { useState } from "react";
import { Box, IconButton, Typography, Button } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import thumbnail from "../../../Images/SignUpBackground.jpeg";
import { Link } from "react-router-dom";

const VideoPlayer = ({ videoSrc, onClose }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        backdropFilter: "blur(10px)",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 9999,
      }}
    >
      <Box width="100%" display="flex" justifyContent="center">
        <IconButton
          sx={{
            color: "#62FFB4",
            border: "2px solid #62FFB4",
            justifyContent: "center",
            backgroundColor: "transparent",
            zIndex: 10000,
          }}
          onClick={onClose}
        >
          <CloseIcon sx={{ fontSize: 15 }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          position: "relative",
          width: "80%",
          maxWidth: "700px",
          maxHeight: "70vh",
          background: "white",
          borderRadius: "10px",
          overflow: "hidden",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
        }}
      >
        {!isPlaying ? (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              paddingTop: "56.25%", // 16:9 Aspect Ratio
              cursor: "pointer",
            }}
            onClick={handlePlay}
          >
            <img
              src={thumbnail}
              alt="Thumbnail"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <IconButton
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "black",
                backgroundColor: "#62FFB4",
                borderRadius: "50%",
                padding: "10px",
              }}
            >
              <PlayArrowIcon sx={{ fontSize: 60 }} />
            </IconButton>
          </Box>
        ) : (
          <video
            controls
            autoPlay
            style={{
              width: "100%",
              maxHeight: "100%",
            }}
          >
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </Box>
      <Box
        sx={{
          textAlign: "center",
          padding: "16px",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
        }}
      >
        <Typography
          variant="body1"
          textAlign="center"
          sx={{
            width: { xs: "90%", sm: "70%", md: "60%" },
            marginBottom: "10px",
            fontSize: {
              xs: "0.6rem",
              sm: "0.7rem",
              md: "0.8rem",
              lg: "1rem",
            },
          }}
        >
          Click below to download your high resolution video that will be fully
          revised by our professional team at AngelicDove before sending it to
          you.
        </Typography>
        <Link to="/checkout">
          <Button
            variant="contained"
            sx={{ backgroundColor: "#62FFB4" }}
            startIcon={<FileDownloadOutlinedIcon />}
          >
            Download HD
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default VideoPlayer;
