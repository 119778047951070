import { useState } from "react";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { useAuthContext } from "./useAuthContext";
import { useVideoContext } from "../Context/VideoContext";

export const usePayment = () => {
  const { user } = useAuthContext();
  const { videoId } = useVideoContext();
  const [loading, setLoading] = useState(false);
  // const [videoUrl, setVideoUrl] = useState(null);
  const [sendEmail, setSendEmail] = useState(false);

  const Payment = async () => {
    if (!user) {
      alert("You must be logged in to make a payment");
      return;
    }

    const stripe = await loadStripe(
      "pk_live_51PbdgIRr32I1Eqb5HHnneR7LKv9WzLAkzMUlej7WD10AXH5pB95HPqsJrhe3l7STjmelTXKbEzOFzv1MgJu6XNjT00RjHQQv2z"
    );

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/payment`,
        {
          videoId: videoId._id,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      const session = await response.data;
      const result = await stripe.redirectToCheckout({ sessionId: session.id });
      if (result.error) {
        console.log(result.error);
      }
    } catch (error) {
      console.error("Error in payment:", error);
    }
  };

  const GeneratePaidVideo = async (videoId) => {
    if (!user) {
      alert("You must be logged in to export the video");
      return;
    }
    setSendEmail(false);
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/test/paid/${videoId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const success = response.data.videoUrl;
      console.log("url ", success);
      // setVideoUrl(success);
      if (success) {
        await axios.post(
          `${process.env.REACT_APP_SERVER_DOMAIN}/api/video/${videoId}/videoLink`,
          {
            videoLink: success,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        console.log("video link updated");
      }
      if (success) {
        await axios.post(
          `${process.env.REACT_APP_SERVER_DOMAIN}/api/email/sendLink`,
          {
            email: user.email,
            videoUrl: success,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setSendEmail(true);
      }
    } catch (error) {
      console.error("Error exporting video:", error);
      alert("Failed to export video");
    } finally {
      setLoading(false);
    }
  };

  return { Payment, GeneratePaidVideo, loading, sendEmail };
};
