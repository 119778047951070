import { useAuthContext } from "./useAuthContext";
import axios from "axios";
import { useCallback } from "react";
import { useVideoContext } from "../Context/VideoContext";
import { useImageContext } from "../Context/ImageContext";

export const useGetAllImages = () => {
  const { user } = useAuthContext();
  const { videoId } = useVideoContext();
  const { imageId, setImageId } = useImageContext();

  const GetImages = useCallback(async () => {
    if (!user) {
      alert("You must be logged in to get the Images");
      return null;
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/video/${videoId._id}/getImagesOfVideo`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log("getimage hook", response.data.Images);
      const ImageURL = response.data.Images.map((asset) => ({
        name: asset.imgName,
        url: asset.imgurl,
        id: asset._id,
      }));

      setImageId(ImageURL);
      // console.log("new images: ", imageId);
    } catch (error) {
      console.error("Error fetching Images:", error);

      return false;
    }
  }, [user, videoId, imageId, setImageId]);

  return { GetImages };
};
