import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import axios from "axios";

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useAuthContext();

  const login = async (email, password) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/auth/login`,
        {
          email,
          password,
        }
      );
      localStorage.setItem("user", JSON.stringify(response.data));
      dispatch({ type: "LOGIN", payload: response.data });
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      setError(error.response?.data?.error || "Login failed");
      return false;
    }
  };

  return { login, error, isLoading };
};
