import React from "react";
import { Box, Typography, Grid, Link, Button } from "@mui/material";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import mainlogo from "../../Images/logo2.png";
import { Link as RouterLink } from "react-router-dom";

const HomepageFooter = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#0A0D0F",
        color: "white",
        minHeight: "80vh",
      }}
    >
      {/* Top Section: Logo and Description */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: {
            xs: "column", // For extra small screens
            sm: "row", // For small screens and up
          },
          justifyContent: {
            xs: "center", // For extra small screens
            sm: "space-around", // For small screens and up
          },
          alignItems: "center",
          gap: 1,
          textAlign: "center",
          backgroundColor: "#DAF1DD",
          height: "50vh", // Set a fixed height for the top section
          paddingY: 4,
        }}
      >
        {/* Logo */}
        <Box>
          <img
            src={mainlogo}
            alt="Logo"
            style={{
              //   width: "40%", // Make logo responsive
              maxWidth: "350px", // Maximum width for large screens
              padding: "10px",
            }}
          />
        </Box>

        {/* Text Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", sm: "flex-start" },
            width: { xs: "80%", sm: "40%" }, // Make logo responsive
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: "13px", md: "16px" },
              color: "black",
              width: "100%",
              textAlign: "start",
            }}
          >
            Honour your loved ones with a beautifully crafted memorial video
            that celebrates their life and legacy.
          </Typography>
          <Button
            component={RouterLink}
            to="/signup"
            variant="contained"
            sx={{
              width: { sm: "60%", md: "40%" },
              backgroundColor: "#00441D",
              color: "white",
              marginTop: "20px",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#00441D",
              },
            }}
          >
            Get Started
          </Button>
        </Box>
      </Box>

      {/* Bottom Section: Links and Social Media */}
      <Box
        sx={{
          height: "40vh", // Set a fixed height for the bottom section
          backgroundColor: "#13251F",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          justifyContent="space-around"
          alignItems="center"
          spacing={2}
          sx={{
            flexDirection: { xs: "column", sm: "row" }, // Responsive direction
            textAlign: { xs: "center", sm: "left" },
            width: "100%",
            paddingX: 4,
          }}
        >
          {/* Left Side: Links */}
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              marginBottom: { xs: "20px", sm: "0" },
              display: "flex",
              justifyContent: { xs: "center", sm: "flex-start" },
            }}
          >
            <Link
              href="/pricing"
              sx={{
                color: "white",
                fontFamily: "poppins",
                fontSize: { xs: "18px", sm: "20px" }, // Responsive font size
                margin: "0 20px",
                fontWeight: 400,
                textDecoration: "none",
              }}
            >
              Pricing
            </Link>
            <Link
              href="/features"
              sx={{
                color: "white",
                fontFamily: "poppins",
                fontSize: { xs: "18px", sm: "20px" }, // Responsive font size
                margin: "0 20px",
                fontWeight: 400,
                textDecoration: "none",
              }}
            >
              Features
            </Link>
            <Link
              href="/support"
              sx={{
                color: "white",
                fontFamily: "poppins",
                fontSize: { xs: "18px", sm: "20px" }, // Responsive font size
                margin: "0 20px",
                fontWeight: 400,
                textDecoration: "none",
              }}
            >
              Support
            </Link>
          </Grid>

          {/* Right Side: Social Media Icons */}
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ textAlign: { xs: "center", sm: "right" } }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "22px", sm: "26px" },
                marginBottom: "10px",
                marginRight: "20px",
              }} // Responsive font size
            >
              Follow us
            </Typography>
            <Box>
              <Link
                href="https://facebook.com"
                target="_blank"
                rel="noopener"
                sx={{ color: "white", margin: "0 20px" }}
              >
                <Facebook fontSize="large" />
              </Link>
              <Link
                href="https://instagram.com"
                target="_blank"
                rel="noopener"
                sx={{ color: "white", margin: "0 20px" }}
              >
                <Instagram fontSize="large" />
              </Link>
              <Link
                href="https://twitter.com"
                target="_blank"
                rel="noopener"
                sx={{ color: "white", margin: "0 20px" }}
              >
                <Twitter fontSize="large" />
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HomepageFooter;
