import { createContext, useState, useEffect, useContext } from "react";

// Format Context
const FormatContext = createContext();

export const useFormatContext = () => useContext(FormatContext);

export const FormatContextProvider = ({ children }) => {
  const [format, setFormat] = useState([]);

  useEffect(() => {
    const storedFormat = window.localStorage.getItem("format");
    if (storedFormat) {
      setFormat(JSON.parse(storedFormat));
    }
  }, []);

  useEffect(() => {
    if (format.length > 0) {
      window.localStorage.setItem("format", JSON.stringify(format));
    }
  }, [format]);

  return (
    <FormatContext.Provider value={{ format, setFormat }}>
      {children}
    </FormatContext.Provider>
  );
};
