import React from "react";
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import Navbar from "./Navbar";
import HomepageFooter from "./HomepageFooter";
function Pricing() {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          backgroundColor: "primary",
          padding: { xs: 3, sm: 4, md: 6 },
          color: "white",
        }}
      >
        {/* Main Heading */}
        <Typography
          variant="h3"
          sx={{
            fontWeight: 600,
            fontSize: { xs: "1.8rem", sm: "2.4rem", md: "2.8rem" },
            textAlign: "left",
            marginBottom: 3,
          }}
        >
          Pricing
        </Typography>

        {/* Introductory Text */}
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
            lineHeight: 1.6,
            textAlign: "left",
            marginBottom: 4,
          }}
        >
          We offer a simple, all-inclusive package to create a beautiful
          memorial video that honors your loved one. Our goal is to make the
          process straightforward and affordable, with all essential features
          included for one transparent price.
        </Typography>

        <Divider sx={{ my: 4, borderColor: "white" }} />
        {/* Package Details */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            mb: 2,
            fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2rem" },
          }}
        >
          Memorial Video Package
        </Typography>
        <List disablePadding sx={{ marginBottom: 4 }}>
          <ListItem sx={{ display: "list-item", paddingLeft: 2 }}>
            <ListItemText primary="Price: $39.99" />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 2 }}>
            <ListItemText primary="Features Included:" />
          </ListItem>
          <List sx={{ paddingLeft: 2 }}>
            <ListItem sx={{ display: "list-item", paddingLeft: 4 }}>
              <ListItemText primary="Upload between 10 to 60 photos" />
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingLeft: 4 }}>
              <ListItemText primary="Choice of background (sea, clouds, mountains, etc.)" />
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingLeft: 4 }}>
              <ListItemText primary="Selection from 5 peaceful, non-copyrighted songs" />
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingLeft: 4 }}>
              <ListItemText primary="Video length up to 5 minutes" />
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingLeft: 4 }}>
              <ListItemText primary="Watermarked preview and full download link after purchase" />
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingLeft: 4 }}>
              <ListItemText primary="Secure, easy payment via Stripe" />
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingLeft: 4 }}>
              <ListItemText primary="Automated email delivery of the final video link" />
            </ListItem>
          </List>
        </List>
        <Divider sx={{ my: 4, borderColor: "white" }} />

        {/* Payment Information */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            mb: 2,
            fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2rem" },
          }}
        >
          How Payment Works
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
            lineHeight: 1.6,
            marginBottom: 3,
          }}
        >
          Our secure payment system, powered by Stripe, makes purchasing your
          video easy and safe. After previewing your video, you’ll be able to
          complete the payment process. Once payment is confirmed, your final
          video will be rendered and emailed to you with a download link.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
            lineHeight: 1.6,
            marginBottom: 4,
          }}
        >
          If you have any questions about our pricing or need assistance, please
          contact our support team. Thank you for trusting us to help create a
          meaningful tribute to your loved one.
        </Typography>
      </Box>
      <HomepageFooter />
    </>
  );
}

export default Pricing;
