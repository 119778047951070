import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "../../Styles/Dashboard.css";
import Dashboardnavbar from "./Dashboardnavbar";
import DashboardVideoCard from "./DashboardVideoCard";
import { useGetAllVideos } from "../../Hooks/useGetAllVideos";
import { useVideoContext } from "../../Context/VideoContext"; // Adjust the path as needed
import { useImageContext } from "../../Context/ImageContext"; // Adjust the path as needed
import { useFormatContext } from "../../Context/FormatContext"; // Adjust the path as needed
import { useNavigate } from "react-router-dom";
import EmptyState from "./EmptyState"; // Import the EmptyState
import logo from "../../Images/logo1.png";
import { useGetAllBackgrounds } from "../../Hooks/useGetBackgrounds";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import DashboardOrderCard from "./DashboardOrderCard";
import useDeleteVideo from "../../Hooks/useDeleteVideo";
import Loader from "../LoadingScreen/Loader";

const Dashboard = () => {
  const { GetBackgrounds } = useGetAllBackgrounds();
  const { setFormat } = useFormatContext();
  const { setVideoId } = useVideoContext();
  const { setImageId } = useImageContext();
  const { GetVideos, GetOrders, loading } = useGetAllVideos();
  const { deletevideo } = useDeleteVideo();
  const [videos, setVideos] = useState([]);
  const [orders, setOrders] = useState([]);
  const [view, setView] = useState("projects"); // State to manage the view
  const navigate = useNavigate();

  const fetchVideosAndOrders = async () => {
    const AllVideos = await GetVideos();
    const AllOrders = await GetOrders();
    if (AllVideos) {
      setVideos(AllVideos.videos || []);
    } else {
      setVideos([]);
    }
    if (AllOrders) {
      setOrders(AllOrders.videos || []);
    } else {
      setOrders([]);
    }
  };

  useEffect(() => {
    fetchVideosAndOrders();
  }, [GetVideos, GetOrders]);

  const handleVideoDelete = async (video) => {
    await deletevideo(video._id);
    fetchVideosAndOrders(); // Re-fetch videos after deletion
  };

  const handleVideoClick = (video) => {
    setVideoId(video);
    GetBackgrounds();
    setFormat(video.aspectRatio);
    const ImageURL = video.images.map((asset) => ({
      name: asset.imgName,
      url: asset.imgurl,
      id: asset._id,
    }));
    setImageId(ImageURL);
    navigate("/edit");
  };

  const handleViewChange = (view) => {
    setView(view);
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Loader text="Fetching ..." />
        </Box>
      );
    }

    // if (error) {
    //   return (
    //     <Box
    //       display="flex"
    //       justifyContent="center"
    //       alignItems="center"
    //       height="100vh"
    //     >
    //       <Typography variant="h6" color="error">
    //         {error.message}
    //       </Typography>
    //     </Box>
    //   );
    // }

    if (view === "projects") {
      return videos.length === 0 ? (
        <EmptyState />
      ) : (
        <Grid container spacing={2}>
          {videos.map((video) => (
            <Grid item key={video._id} xs={12} sm={6} md={6} lg={4} xl={3}>
              <DashboardVideoCard
                video={video}
                onClick={handleVideoClick}
                onDelete={handleVideoDelete} // Pass the delete handler
              />
            </Grid>
          ))}
        </Grid>
      );
    } else if (view === "orders") {
      return orders.length === 0 ? (
        <EmptyState />
      ) : (
        <Grid container spacing={2}>
          {orders.map((order) => (
            <Grid item key={order._id} xs={12} sm={12} md={6}>
              <DashboardOrderCard video={order} />
            </Grid>
          ))}
        </Grid>
      );
    }
  };

  return (
    <Grid container className="MainPanel">
      <Grid
        item
        md={3}
        sx={{
          display: { xs: "none", md: "block" },
        }}
        className="leftPanel"
      >
        <img src={logo} alt="logo" width="300px" />
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Button
            variant="outlined"
            className="projectButton"
            sx={{
              margin: "50px 0 0 0px",
              borderRadius: "9.25px",
              border: "2px solid #DAF1DD",
              width: "auto",
              "&:hover": {
                borderRadius: "9.25px", // Ensure border radius remains the same on hover
                border: "2px solid #DAF1DD", // Keep border style consistent on hover
              },
            }}
            onClick={() => handleViewChange("projects")}
          >
            <WidgetsOutlinedIcon
              color="secondary"
              sx={{ fontSize: 30, mr: 1 }}
            />
            My Projects
          </Button>
          <Button
            variant="outlined"
            className="projectButton"
            sx={{
              margin: "50px 0 0 0px",
              borderRadius: "9.25px",
              border: "2px solid #DAF1DD",
              width: "auto",
              "&:hover": {
                borderRadius: "9.25px", // Ensure border radius remains the same on hover
                border: "2px solid #DAF1DD", // Keep border style consistent on hover
              },
            }}
            onClick={() => handleViewChange("orders")}
          >
            <CloudDoneIcon color="secondary" sx={{ fontSize: 30, mr: 1 }} />
            My Orders
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} md={9} className="rightPanel">
        <Dashboardnavbar />
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <Typography variant="h4">
            {view === "projects" ? "PROJECTS" : "ORDERS"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            justifyContent: "center",
            mb: 2,
          }}
        >
          <Button
            onClick={() => handleViewChange("projects")}
            sx={{
              color: view === "projects" ? "white" : "grey",
              textTransform: "none",
              borderBottom: view === "projects" ? "2px solid white" : "none",
              fontSize: { xs: "2rem", sm: "2.25rem" },
              mr: 2,
            }}
          >
            Projects
          </Button>
          <Button
            onClick={() => handleViewChange("orders")}
            sx={{
              color: view === "orders" ? "white" : "grey",
              textTransform: "none",
              borderBottom: view === "orders" ? "2px solid white" : "none",
              fontSize: { xs: "2rem", sm: "2.25rem" },
            }}
          >
            Orders
          </Button>
        </Box>
        {renderContent()}
      </Grid>
    </Grid>
  );
};

export default Dashboard;
