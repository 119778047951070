import { useState } from "react";
import axios from "axios";
import { useAuthContext } from "./useAuthContext";

export const useSendContact = () => {
  const { user } = useAuthContext();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const sendContact = async (Firstname, Lastname, email, message) => {
    setIsLoading(true);
    setError(null);

    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/contact/create`,
        {
          Firstname,
          Lastname,
          email,
          message,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setIsLoading(false);
      return true;
    } catch (err) {
      setIsLoading(false);

      // More specific error handling
      if (err.response) {
        setError(err.response.data.error || "Failed to send contact form.");
      } else if (err.request) {
        setError("No response from server. Please try again later.");
      } else {
        setError("Error sending contact form. Please try again.");
      }

      return false;
    }
  };

  return { sendContact, error, isLoading };
};
