import React from "react";
import { Box, Card, CardContent, Typography, IconButton } from "@mui/material";
import { PlayArrow } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";

const DashboardVideoCard = ({ video, onClick, onDelete }) => {
  if (!video) return null;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Card
        sx={{
          bgcolor: "#62FFB444",
          paddingTop: "20px",
          color: "#fff",
          width: "289px",
          minHeight: "289px",
          margin: "20px",
          borderRadius: "12px",
          "&:hover .delete-button": {
            display: "flex",
          },
        }}
      >
        <CardContent>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            py={2}
          >
            <Box
              sx={{
                width: "100%",
                height: "150px", // Set the same height as your original image
                backgroundColor: "#3F806F",
                borderRadius: "11px",
              }}
            />
            <IconButton
              sx={{
                position: "absolute",
                backgroundColor: "#62FFB4",
                borderRadius: "50%",
                padding: "10px",
                transition: "background-color 0.3s ease",
              }}
              onClick={() => onClick(video)}
            >
              <PlayArrow sx={{ fontSize: 30, color: "#ffffff" }} />
            </IconButton>
          </Box>
          <Typography
            variant="h5"
            align="left"
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            lineHeight="1.7em"
          >
            {video.title}
            <IconButton
              className="delete-button"
              sx={{
                display: "none",
                backgroundColor: "transparent",
                borderRadius: "50%",
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#4bcfa6",
                },
              }}
              onClick={() => onDelete(video)}
            >
              <DeleteIcon color="primary" />
            </IconButton>
          </Typography>
          <Typography variant="body2" align="left">
            Last edited {new Date(video.updatedAt).toLocaleDateString()}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DashboardVideoCard;
