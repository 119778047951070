import React, { createContext, useContext, useState, useEffect } from "react";

const VideoContext = createContext();

export const useVideoContext = () => useContext(VideoContext);

export const VideoContextProvider = ({ children }) => {
  const [videoId, setVideoId] = useState(null);

  useEffect(() => {
    const storedVideoId = window.localStorage.getItem("video_ID");
    if (storedVideoId) {
      setVideoId(JSON.parse(storedVideoId));
    }
  }, []);

  useEffect(() => {
    if (videoId !== null) {
      window.localStorage.setItem("video_ID", JSON.stringify(videoId));
    }
  }, [videoId]);
  console.log("Video Context ", videoId);
  return (
    <VideoContext.Provider value={{ videoId, setVideoId }}>
      {children}
    </VideoContext.Provider>
  );
};
