import React, { createContext, useState, useEffect, useContext } from "react";

// Song Context
const SongContext = createContext();

export const useSongContext = () => useContext(SongContext);

export const SongContextProvider = ({ children }) => {
  const [song, setSong] = useState(null);

  useEffect(() => {
    const storedSong = window.localStorage.getItem("Song");
    if (storedSong) {
      setSong(JSON.parse(storedSong));
    }
  }, []);

  useEffect(() => {
    if (song !== null) {
      window.localStorage.setItem("Song", JSON.stringify(song));
    }
  }, [song]);

  return (
    <SongContext.Provider value={{ song, setSong }}>
      {children}
    </SongContext.Provider>
  );
};
