import React, { useEffect, useState, useContext } from "react";
import { Box, Typography, IconButton, Slider } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { useVideoContext } from "../../../Context/VideoContext";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import { useGetAllSongs } from "../../../Hooks/useGetSongs";
import { useSongContext } from "../../../Context/SongContext";
import { useGetAllVideos } from "../../../Hooks/useGetAllVideos";
import { PopupWindowContext } from "../../../Context/PopupWindowContext";
import styles from "../../../Styles/AddSong.module.css";

function AddSong({ component, handleClose }) {
  const { GetVideoRefresh } = useGetAllVideos();
  const { song } = useSongContext();
  const { user } = useAuthContext();
  const { GetSongs } = useGetAllSongs();
  const { videoId } = useVideoContext();
  const { setFormState } = useContext(PopupWindowContext);

  const [loading, setLoading] = useState({});
  const [added, setAdded] = useState(null); // Store only one song ID
  const [playing, setPlaying] = useState(null);
  const [currentTime, setCurrentTime] = useState({});
  const [audio, setAudio] = useState(null);
  const [duration, setDuration] = useState({});

  useEffect(() => {
    GetSongs();
  }, [GetSongs]);

  useEffect(() => {
    if (audio) {
      audio.addEventListener("timeupdate", handleTimeUpdate);
      audio.addEventListener("ended", handleSongEnd);
      audio.addEventListener("loadedmetadata", handleLoadedMetadata);
      return () => {
        audio.removeEventListener("timeupdate", handleTimeUpdate);
        audio.removeEventListener("ended", handleSongEnd);
        audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
        audio.pause();
        audio.currentTime = 0;
      };
    }
  }, [audio]);

  const handleSongSelect = (song) => {
    setLoading((prev) => ({ ...prev, [song._id]: true }));

    setFormState((prevState) => ({
      ...prevState,
      AddSong: song.SongUrl,
    }));
    setAdded(song._id); // Store only the selected song ID
    setLoading((prev) => ({ ...prev, [song._id]: false }));
  };

  const handleAddClick = async (song) => {
    if (!user) {
      alert("You must be logged in to update the song");
      return;
    }

    setLoading((prev) => ({ ...prev, [song._id]: true }));

    try {
      await axios.patch(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/video/${videoId._id}/bg-music`,
        {
          backgroundMusic: song.SongUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      GetVideoRefresh(videoId._id);
      console.log("Song updated successfully");
      handlePauseClick();
      setTimeout(() => {
        handleClose();
      }, 500);
      setAdded(song._id); // Store only the selected song ID
    } catch (error) {
      console.error("Error updating song:", error);
      alert("Failed to update song");
    } finally {
      setLoading((prev) => ({ ...prev, [song._id]: false }));
    }
  };

  const handlePlayClick = (song) => {
    if (audio) {
      audio.pause();
      setPlaying(null);
    }

    const newAudio = new Audio(song.SongUrl);
    setAudio(newAudio);
    setPlaying(song._id);
    newAudio.play();
  };

  const handlePauseClick = () => {
    if (audio) {
      audio.pause();
    }
    setPlaying(null);
  };

  const handleTimeUpdate = () => {
    setCurrentTime((prev) => ({
      ...prev,
      [playing]: audio.currentTime,
    }));
  };

  const handleSongEnd = () => {
    setPlaying(null);
  };

  const handleLoadedMetadata = () => {
    setDuration((prev) => ({
      ...prev,
      [playing]: audio.duration,
    }));
  };

  const handleSliderChange = (event, newValue, song) => {
    if (audio && playing === song._id) {
      audio.currentTime = newValue;
      setCurrentTime((prev) => ({
        ...prev,
        [song._id]: newValue,
      }));
    }
  };

  return (
    <Box
      width={"100%"}
      display="flex"
      justifyContent="center"
      alignContent={"center"}
    >
      <Box
        height="280px"
        pt={1}
        mb={2}
        sx={{
          width: { xs: component === "wizard" ? "92vw" : "100vw", md: "520px" },
          borderRadius: "10px",
        }}
        display="flex"
        justifyContent="center"
        alignContent={"center"}
        backgroundColor="#14181B"
      >
        <Box
          className={styles.songList}
          width={"90%"}
          sx={{ padding: { xs: 0, sm: 1, md: "0 20px 40px 20px" } }}
        >
          {song &&
            song.map((song, index) => (
              <Box
                key={index}
                className={styles.songItem}
                sx={{
                  gap: { sx: 0, md: 2 },
                  padding: { xs: "4px", sm: "10px" },
                  mr: 1,
                  mt: 1,
                }}
              >
                <Box className={styles.songDetails}>
                  <AudiotrackIcon
                    className={styles.songIcon}
                    fontSize="large"
                    sx={{ color: "#3F806F ", marginRight: 0 }}
                  />
                  <Box
                    className="song-text"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: { xs: "60px", sm: "100px", md: "100%" },
                    }}
                  >
                    <Typography
                      variant="body1"
                      className={styles.songTitle}
                      sx={{ fontSize: { xs: "0.7rem", sm: "0.86rem" } }}
                    >
                      {song.title}
                    </Typography>
                  </Box>
                </Box>
                <Box className={styles.songControls}>
                  <Slider
                    sx={{
                      width: { xs: "45px", sm: "70px", md: "100px" },
                      color: "#62FFB4",
                      "& .MuiSlider-thumb": {
                        height: "14.83px",
                        width: "4.45px",
                        backgroundColor: "#62FFB4",
                        borderRadius: "2px",
                        boxShadow: "none",
                      },
                      "& .MuiSlider-track": {
                        backgroundColor: "#62FFB4",
                        height: "1.83px",
                      },
                      "& .MuiSlider-rail": { backgroundColor: "#62FFB4" },
                    }}
                    min={0}
                    max={duration[song._id] || 100}
                    value={currentTime[song._id] || 0}
                    onChange={(event, newValue) =>
                      handleSliderChange(event, newValue, song)
                    }
                  />
                  <Typography
                    variant="body2"
                    className={styles.songDuration}
                    sx={{ fontSize: { xs: "0.7rem", sm: "1rem" } }}
                  >
                    {song.duration}
                  </Typography>
                  <IconButton
                    className={styles.songIcon}
                    onClick={
                      playing === song._id
                        ? handlePauseClick
                        : () => handlePlayClick(song)
                    }
                    sx={{ padding: { xs: "0", sm: "1" } }}
                  >
                    {playing === song._id ? (
                      <PauseIcon
                        fontSize="large"
                        sx={{
                          color: "#62FFB4",
                          fontSize: { xs: "1.8rem", sm: "1.8rem" },
                        }}
                      />
                    ) : (
                      <PlayArrowIcon
                        fontSize="medium"
                        sx={{
                          color: "white",
                          fontSize: { xs: "1.5rem", sm: "1.8rem" },
                          "&:hover": { color: "#62FFB4" },
                        }}
                      />
                    )}
                  </IconButton>
                  <IconButton
                    className={styles.songIcon}
                    onClick={() => {
                      component === "wizard"
                        ? handleSongSelect(song)
                        : handleAddClick(song);
                    }}
                    sx={{ padding: { xs: "0.5", sm: "1" } }}
                  >
                    {loading[song._id] ? (
                      <CircularProgress size={24} sx={{ color: "#62FFB4" }} />
                    ) : added === song._id ? (
                      <CheckIcon sx={{ color: "#62FFB4" }} />
                    ) : (
                      <AddIcon
                        sx={{
                          color: "white",
                          border: "2px solid white",
                          borderRadius: "50%",
                          "&:hover": { color: "#62FFB4" },
                        }}
                      />
                    )}
                  </IconButton>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
}

export default AddSong;
