import React from "react";
import { Grid, Box } from "@mui/material";

import PopupWindow from "./PopupWindow/PopupWindow";

const VideoEditing = () => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <PopupWindow />
      </Box>
    </Grid>
  );
};

export default VideoEditing;
