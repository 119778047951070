import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Button,
  Collapse,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { ExpandMore, ExpandLess, Add as AddIcon } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useLogout } from "../../Hooks/useLogout";
import WizardEdit from "../EditScreen/Wizard/WizardEdit";
import "../../Styles/Dashboard.css";

import logoMobile from "../../Images/logo.png";

function Dashboardnavbar() {
  const { logout } = useLogout();
  const isXsOrSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [isWizardEditOpen, setIsWizardEditOpen] = useState(false);
  const [openAccount, setOpenAccount] = useState(false);
  const [dropdownPositionAccount, setDropdownPositionAccount] = useState({
    top: 0,
    left: 0,
  });
  const AccountButtonRef = useRef(null);

  const navigate = useNavigate();
  const handleClickAccount = () => {
    setOpenAccount((prev) => !prev);
  };

  useEffect(() => {
    if (AccountButtonRef.current) {
      const rect = AccountButtonRef.current.getBoundingClientRect();
      setDropdownPositionAccount({ top: rect.bottom, left: rect.left - 13 });
    }
  }, [openAccount]);

  const handleLogout = async () => {
    const success = await logout();
    console.log("logging outt");
    if (success) {
      navigate("/");
    }
  };
  const handleCloseWizard = () => {
    setIsWizardEditOpen(false);
  };

  const CreateNewVideo = async () => {
    setIsWizardEditOpen(true);
  };
  const NavLink = ({ to, primary }) => (
    <Link
      to={to}
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
    >
      <ListItem disablePadding={isXsOrSm}>
        <ListItemButton>
          <ListItemText
            primary={primary}
            sx={{
              "&:hover": {
                color: "#00C853",
              },
            }}
          />
        </ListItemButton>
      </ListItem>
    </Link>
  );

  return (
    <div>
      <Box
        className="header"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "space-between", md: "flex-end" },

          width: { xs: "95vw", md: "100%" },
        }}
      >
        <Box sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
          <Link to="/dashboard">
            <img src={logoMobile} alt="logo" style={{ width: "50px" }} />
          </Link>
        </Box>
        {isWizardEditOpen && <WizardEdit onClose={handleCloseWizard} />}
        <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={CreateNewVideo}
            sx={{
              fontSize: {
                xs: "0.7rem",
                sm: "0.7rem",
                md: "0.8rem",
                lg: "1rem",
              },
              mr: 3,
              textTransform: "none",
              borderRadius: "8.75px",
            }}
            startIcon={
              // isXsOrSm ? (
              <AddIcon
                sx={{
                  border: "1px solid black",
                  borderRadius: 50,
                }}
              />
              // ) : null
            }
          >
            {isXsOrSm ? "Create" : "Create a Video"}
          </Button>
          <Button
            ref={AccountButtonRef}
            color="primary"
            className="Menu-items"
            onClick={handleClickAccount}
            disableRipple
            startIcon={openAccount ? <ExpandLess /> : <ExpandMore />}
            sx={{
              fontSize: {
                xs: "0.7rem",
                sm: "0.7rem",
                md: "0.8rem",
                lg: "1rem",
              },
              textTransform: "none",
            }}
          >
            {isXsOrSm ? "Accounts" : "My Account"}
          </Button>
          <Collapse in={openAccount} timeout="auto" unmountOnExit>
            <Paper
              elevation={1}
              sx={{
                position: "absolute",
                top: dropdownPositionAccount.top,
                left: dropdownPositionAccount.left,
                zIndex: 1,
                mt: 1,
              }}
            >
              <List component="div" disablePadding>
                <NavLink to="/account" primary="Account" />
                <NavLink to="/contact" primary="Contact" />
                <ListItem onClick={handleLogout} disablePadding={isXsOrSm}>
                  <ListItemButton>
                    <ListItemText
                      primary="Log out"
                      sx={{
                        "&:hover": {
                          color: "#00C853",
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Paper>
          </Collapse>
        </Box>
      </Box>
    </div>
  );
}

export default Dashboardnavbar;
