import React from "react";
import { AppBar, Grid, Box, TextField, Button, Divider } from "@mui/material";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import PopupWindow from "./PopupWindow/PopupWindow"; // Adjust the path as necessary
import { Link } from "react-router-dom";
import renameLogo from "../../Images/rename.png";

const Navbar = ({
  logo,
  isRenaming,
  videoName,
  setVideoName,
  handleSaveClick,
  handleRenameClick,
}) => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        maxHeight: { md: "12%", lg: "8%" },
        display: { xs: "none", md: "flex" },
      }}
    >
      <AppBar
        position="static"
        sx={{ backgroundColor: "#000", padding: "10px" }}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#181C1F",
                borderRadius: 2,
                paddingX: "4px",
              }}
            >
              <Link to="/dashboard">
                <img
                  src={logo}
                  alt="LOGO"
                  width="35px"
                  height="35px"
                  style={{
                    padding: "5px 5px",
                  }}
                />
              </Link>
              {isRenaming ? (
                <>
                  <TextField
                    value={videoName}
                    onChange={(e) => setVideoName(e.target.value)}
                    size="small"
                    sx={{
                      marginRight: 1,
                      width: "120px", // Adjust this value as needed to match the button width
                      "& .MuiInputBase-input": {
                        padding: "5px 8px", // Adjust padding as needed
                      },
                    }}
                  />
                  <Button
                    color="primary"
                    sx={{ textTransform: "none", marginRight: 1 }}
                    onClick={handleSaveClick}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    sx={{
                      display: { xs: "none", lg: "flex" },
                      backgroundColor: "grey",
                      ml: 1,
                      height: "20px", // Set your desired height here
                    }}
                  />
                  <Button
                    color="primary"
                    sx={{
                      textTransform: "none",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.7rem",
                        md: "0.8rem",
                        lg: "1rem",
                      },
                      fontWeight: "200",
                    }}
                    onClick={handleRenameClick}
                  >
                    {" "}
                    {/* <DriveFileRenameOutlineIcon sx={{ fontSize: 20, mr: 1 }} /> */}
                    <img
                      src={renameLogo}
                      alt="LOGO"
                      width="25px"
                      height="25px"
                      style={{
                        padding: "2px",
                      }}
                    />
                    Rename Video
                  </Button>
                </>
              )}
            </Box>
          </Grid>
          <Grid item>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#181C1F",
                borderRadius: 1,
                // paddingX: 2,
                // marginRight: 2,
              }}
            >
              <PopupWindow />
            </Box>
          </Grid>
          <Grid item>
            <Link to="/checkout">
              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{
                  textTransform: "none",
                  // width: { xs: "50px", sm: "100px", md: "100px", lg: "150px" },
                  fontSize: {
                    xs: "0.7rem",
                    sm: "0.7rem",
                    md: "0.8rem",
                    lg: "1rem",
                  },
                  fontWeight: "400",
                  // p: { xs: "5px", sm: "10px", md: "15px", lg: "20px" },
                }}
              >
                <IosShareRoundedIcon
                  sx={{
                    fontSize: { xs: 15, sm: 20, md: 20, lg: 20 },

                    mr: 1,
                    opacity: 0.9,
                  }}
                />
                Export My Video
              </Button>
            </Link>
          </Grid>
        </Grid>
      </AppBar>
    </Grid>
  );
};
export default Navbar;
