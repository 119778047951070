import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { Box, Button, Typography, Alert } from "@mui/material";
import CloudDoneSharpIcon from "@mui/icons-material/CloudDoneSharp";
import axios from "axios";
import "../../Styles/Dropzone.css"; // Ensure this file contains the necessary styles
import { useVideoContext } from "../../Context/VideoContext"; // Adjust the path as needed
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useImageContext } from "../../Context/ImageContext"; // Adjust the path as needed
import { PopupWindowContext } from "../../Context/PopupWindowContext";
import Loader from "../LoadingScreen/Loader";

const Dropzone = ({ onClose, component }) => {
  const { setImageId } = useImageContext();
  const { user } = useAuthContext();
  const { videoId } = useVideoContext();
  const dropzoneRef = useRef(null);
  const { setFormState } = useContext(PopupWindowContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const formData = new FormData();
      acceptedFiles.forEach((file) => {
        formData.append("imgTitle", file);
      });

      if (component === "wizard") {
        setFormState((prevState) => ({
          ...prevState,
          Dropzone: formData,
        }));
      } else {
        setLoading(true);
        setError(null);
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_SERVER_DOMAIN}/api/video/${videoId._id}/images`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${user.token}`,
              },
            }
          );
          const ImageURL = response.data.assets.map((asset) => ({
            name: asset.imgName,
            url: asset.imgurl,
            id: asset._id,
          }));
          setImageId(ImageURL);
          onClose();
        } catch (error) {
          console.error("Error uploading images:", error.response.data.message);
          setError(`Error uploading images. ${error.response.data.message}`);
        } finally {
          setLoading(false);
        }
      }
    },
    [videoId, user, onClose, setImageId, component, setFormState]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleClickOutside = (event) => {
    if (dropzoneRef.current && !dropzoneRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box minHeight="300px">
      <Box
        {...getRootProps()}
        className={component === "wizard" ? "wizard-dropzone" : "dropzone"}
        sx={{
          zIndex: 9999,
          minWidth: { xs: "70vw", sm: "50vw", md: "40vw", lg: "40vw" },
        }}
        ref={dropzoneRef}
      >
        <input {...getInputProps()} />
        <CloudDoneSharpIcon sx={{ fontSize: 35, color: "#FFFFFF", mb: 1 }} />
        <Typography
          variant="h5"
          component="div"
          sx={{
            color: "#FFFFFF",
            mb: { xs: 1, md: 2 },
            lineHeight: "26.57px",
            fontWeight: "500",
            fontSize: {
              xs: "0.9rem",
              sm: "1rem",
              md: "1.2rem",
              lg: "1.3rem",
            },
          }}
        >
          {component === "wizard"
            ? "Choose up to 80 images of your loved one!"
            : "Add new Image Blocks"}
        </Typography>
        <Typography
          variant="h6"
          component="div"
          justifyContent="center"
          textAlign="center"
          sx={{
            width: "80%",
            color: "#FFFFFF",
            mb: { xs: 1, md: 2 },
            lineHeight: "26.57px",
            fontWeight: "500",
            fontSize: {
              xs: "0.7rem",
              sm: "1rem",
              md: "1rem",
              lg: "1.1rem",
            },
          }}
        >
          Upload Your Image, Supported file formats are JPEG, JPG, PNG
        </Typography>
        <Button
          variant="contained"
          size="large"
          sx={{
            fontWeight: "500",
            fontSize: { sm: "16.96px", lg: "18.96px" },
            borderRadius: "11.71px",
          }}
        >
          Browse File
        </Button>
        {/* {loading && <CircularProgress sx={{ mt: 2 }} />} */}
        {loading && <Loader text="uploading images" />}
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default Dropzone;
