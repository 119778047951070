import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Alert, Box, Button, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import { useVideoContext } from "../../../Context/VideoContext"; // Adjust the path as needed
import { useAuthContext } from "../../../Hooks/useAuthContext";
import { useGetAllImages } from "../../../Hooks/useGetImages";
import "../../../Styles/Dropzone.css";
import Loader from "../../LoadingScreen/Loader";

const SidebarDropzone = ({ onClose, selectedImage, setSelectedImage }) => {
  const { user } = useAuthContext();
  const { videoId } = useVideoContext();
  const { GetImages } = useGetAllImages();
  const dropzoneRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      console.log(acceptedFiles);

      const formData = new FormData();
      acceptedFiles.forEach((file) => {
        formData.append("imgTitle", file);
      });
      setLoading(true);
      setError(null);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_DOMAIN}/api/video/${videoId._id}/${selectedImage.id}/update-img`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        const imageAssets = response.data.assets.map((asset) => ({
          url: asset.imgurl,
          id: asset._id,
        }));

        console.log("new image :", imageAssets);
        console.log("response.data.selectedImage", response.data.selectedImage);
        const newSelectedImage = imageAssets.find(
          (asset, index) => index === response.data.selectedImage
        );

        if (newSelectedImage) {
          setSelectedImage(newSelectedImage);
        }

        GetImages();
        onClose();
      } catch (error) {
        console.error("Error uploading images:", error);
        setError("Error uploading images. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    [videoId, user, onClose, selectedImage, setSelectedImage, GetImages]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const handleClickOutside = (event) => {
    if (dropzoneRef.current && !dropzoneRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box
      {...getRootProps()}
      className="dropzone"
      sx={{
        zIndex: 9999,
        minWidth: { xs: "70vw", sm: "50vw", md: "40vw", lg: "40vw" },
        // position: "absolute", // Ensure the Box is positioned absolutely
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
      ref={dropzoneRef}
    >
      <input {...getInputProps()} />
      <CloudUploadIcon sx={{ fontSize: 35, color: "#FFFFFF", mb: 1 }} />
      <Typography variant="h5" component="div" sx={{ color: "#FFFFFF", mb: 2 }}>
        Change Selected Image
      </Typography>
      <Typography
        variant="h6"
        component="div"
        justifyContent="center"
        textAlign="center"
        sx={{
          color: "#FFFFFF",
          width: "80%",
          mb: 2,
          fontSize: {
            xs: "0.7rem",
            sm: "1rem",
            md: "1rem",
            lg: "1.1rem",
          },
        }}
      >
        Upload Your Image, Supported file formats are JPEG, JPG, PNG
      </Typography>
      <Button
        variant="contained"
        sx={{
          fontWeight: "500",
          fontSize: { sm: "16.96px", lg: "18.96px" },
          borderRadius: "11.71px",
        }}
      >
        Browse File
      </Button>{" "}
      {loading && <Loader text="Changing image" />}
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Box>
  );
};

export default SidebarDropzone;
