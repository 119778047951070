import { useState } from "react";
import { useVideoContext } from "../Context/VideoContext";
import { useAuthContext } from "./useAuthContext";
import axios from "axios";
import { useImageContext } from "../Context/ImageContext";
import useDeleteVideo from "./useDeleteVideo";

export const useCreateTemplateVideo = () => {
  const { user } = useAuthContext();
  const { setVideoId } = useVideoContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { setImageId } = useImageContext();
  const { deletevideo } = useDeleteVideo();

  const handleGetStarted = async (formState) => {
    setLoading(true);
    setError(null);

    localStorage.removeItem("selectedImage");
    localStorage.removeItem("backgroundImage");
    localStorage.removeItem("format");
    localStorage.removeItem("imageId");

    const data = {
      title: "Untitled",
      backgroundImage: formState.Background,
      backgroundMusic: formState.AddSong,
      aspectRatio: formState.AddFormat,
    };

    const missingFields = [];
    if (!formState.Background) missingFields.push("Background Image");
    if (!formState.AddSong) missingFields.push("Background Music");
    if (!formState.AddFormat) missingFields.push("Format");

    if (missingFields.length > 0) {
      setLoading(false);
      setError(`Missing fields: ${missingFields.join(", ")}`);
      return false;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/video/create`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setVideoId(response.data.video);
      console.log("formState.Dropzone ", formState.Dropzone);
      if (formState.Dropzone) {
        // If formState.Dropzone is true, upload images
        const formData = formState.Dropzone;
        try {
          const uploadResponse = await axios.post(
            `${process.env.REACT_APP_SERVER_DOMAIN}/api/video/${response.data.video._id}/images`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${user.token}`,
              },
            }
          );

          const ImageURL = uploadResponse.data.assets.map((asset) => ({
            name: asset.imgName,
            url: asset.imgurl,
            id: asset._id,
          }));

          console.log("Images uploaded successfully:", ImageURL);
          setImageId(ImageURL);
        } catch (uploadError) {
          console.error(
            "Error uploading images:",
            uploadError.response.data.message
          );
          setError(
            `Error uploading images ${uploadError.response.data.message}`
          );
          await deletevideo(response.data.video._id);
          // console.log("deleted the video ");
          setLoading(false);
          return false;
        }
      }

      setLoading(false);
      return true;
    } catch (error) {
      console.error("Error:", error);
      setError("Error creating video");
      setLoading(false);
      return false;
    }
  };

  return { handleGetStarted, loading, error, setError };
};
