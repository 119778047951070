import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Avatar,
  Rating,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const testimonials = [
  {
    name: "Leo",
    rating: 4,
    image: "https://randomuser.me/api/portraits/men/1.jpg",
    feedback:
      "The memorial video created for my loved one was absolutely breathtaking. It truly captured their essence and moved me to tears.",
  },
  {
    name: "Anna",
    rating: 5,
    image: "https://randomuser.me/api/portraits/women/1.jpg",
    feedback:
      "The video brought back so many beautiful memories and was a great tribute to my family. The team did a wonderful job!",
  },
  {
    name: "John",
    rating: 5,
    image: "https://randomuser.me/api/portraits/men/3.jpg",
    feedback:
      "Highly recommend their services! The video was well-crafted and truly honored my loved one's life.",
  },
  {
    name: "Mia",
    rating: 4,
    image: "https://randomuser.me/api/portraits/women/3.jpg",
    feedback:
      "I was very impressed by the quality of the video. It captured the essence of our special moments perfectly.",
  },
];

const Slideshow = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleNext = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + 1 + testimonials.length) % testimonials.length
    );
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  const getLeftIndex = (index) =>
    (index - 1 + testimonials.length) % testimonials.length;

  const getRightIndex = (index) => (index + 1) % testimonials.length;

  return (
    <Box
      sx={{
        backgroundColor: "#0A0D0F",
        padding: "40px 20px",
        minHeight: { xs: "70vh", md: "100vh" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {/* Header */}
      <Typography
        variant={isSmallScreen ? "h5" : "h4"}
        sx={{
          textAlign: "center",
          color: "white",
          marginBottom: "30px",
          fontWeight: 600,
        }}
      >
        What Our Clients Say About Us
      </Typography>

      {/* Card Container */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{
          flexDirection: isSmallScreen ? "column" : "row",
        }}
      >
        {/* Left Card */}
        {!isSmallScreen && (
          <Grid item>
            <TestimonialCard
              testimonial={testimonials[getLeftIndex(currentIndex)]}
              scale={0.85}
            />
          </Grid>
        )}

        {/* Center Card */}
        <Grid item>
          <TestimonialCard
            testimonial={testimonials[currentIndex]}
            scale={isSmallScreen ? 1 : 1.1}
            isCenter
          />
        </Grid>

        {/* Right Card */}
        {!isSmallScreen && (
          <Grid item>
            <TestimonialCard
              testimonial={testimonials[getRightIndex(currentIndex)]}
              scale={0.85}
            />
          </Grid>
        )}
      </Grid>

      {/* Custom Navigation and Indicator Container */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        {/* Left Navigation Button */}
        <IconButton onClick={handlePrev} sx={{ margin: "0 10px" }}>
          <ArrowBackIosNewIcon sx={{ color: "#62FFB4" }} />
        </IconButton>

        {/* Indicator Dots */}
        {testimonials.map((_, idx) => (
          <Box
            key={idx}
            sx={{
              width: 12,
              height: 12,
              backgroundColor: idx === currentIndex ? "#62FFB4" : "#555",
              borderRadius: "50%",
              margin: "0 4px",
              cursor: "pointer",
            }}
            onClick={() => setCurrentIndex(idx)}
          />
        ))}

        {/* Right Navigation Button */}
        <IconButton onClick={handleNext} sx={{ margin: "0 10px" }}>
          <ArrowForwardIosIcon sx={{ color: "#62FFB4" }} />
        </IconButton>
      </Box>
    </Box>
  );
};

// Reusable Testimonial Card Component
const TestimonialCard = ({ testimonial, scale, isCenter }) => {
  return (
    <Box
      sx={{
        width: isCenter ? "300px" : "200px",
        padding: "20px",
        marginX: "10px",
        transform: `scale(${scale})`,
        transition: "transform 0.3s",
        backgroundColor: "#181C1F",
        borderRadius: "12px",
        boxShadow: isCenter ? "0 8px 16px rgba(0, 0, 0, 0.5)" : "none",
        textAlign: "center",
      }}
    >
      <Avatar
        src={testimonial.image}
        sx={{ width: 80, height: 80, marginBottom: 2, marginX: "auto" }}
      />
      <Typography variant="h6" sx={{ color: "white", fontWeight: 600 }}>
        {testimonial.name}
      </Typography>
      <Rating
        name="read-only"
        value={testimonial.rating}
        readOnly
        sx={{ color: "#62FFB4", marginBottom: 2 }}
      />
      <Typography
        variant="body1"
        sx={{ color: "white", fontSize: isCenter ? "0.9rem" : "0.8rem" }}
      >
        {testimonial.feedback}
      </Typography>
    </Box>
  );
};

export default Slideshow;
