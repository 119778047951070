// src/components/Sidebar.jsx
import React, { useState } from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import Dummy from "../../../Images/SlidebarDummyImage.png";
import SidebarDropzone from "./SidebarDropzone";
import ImageDuration from "./ImageDuration"; // Import the new component

function Sidebar({ selectedImage, setSelectedImage, videoId }) {
  // const [duration, setDuration] = useState(3); // Duration in seconds
  const [showDropzone, setShowDropzone] = useState(false);

  const imageUrl = selectedImage ? `${selectedImage.url}` : Dummy;
  const imageName = selectedImage
    ? `${selectedImage.name}`
    : "Please Select an Image";

  const handleDropzoneClose = () => {
    setShowDropzone(false);
  };

  return (
    <Grid
      item
      xs={12}
      md={2.5}
      lg={2.3}
      sx={{
        backgroundColor: "#181C1F",
        padding: { xs: "14px", md: "10px" },
        position: "relative", // Added for positioning the Dropzone overlay
        width: "100%",
        display: "flex",
        justifyContent: { xs: "center", md: "flex-start" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          paddingX: 1,
          // ml: 1,
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            color: "#FFFFFF",
            marginTop: { xs: "10px", md: "15px" },
            marginBottom: { xs: "13px", md: "25px" },
          }}
        >
          VIDEO SETTING
        </Typography>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: { xs: "10px", md: "30px" },
          }}
        >
          <Box width="100%">
            <Typography
              variant="body2"
              sx={{
                color: "#FFFFFF",
                marginBottom: "13px",
              }}
              justifyContent="flex-start"
            >
              CHANGE IMAGE
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: "#172421", // Dark green background
              maxWidth: "200px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              borderRadius: "8px", // Match the image border radius
            }}
          >
            <img
              src={imageUrl}
              alt="sidebar"
              style={{
                maxWidth: "180px", // Ensure image doesn't overflow the container
                maxHeight: "150px", // Ensure image doesn't overflow the container
                borderRadius: "8px",
                padding: "10px 10px 5px 10px ", // Optional padding around the image
              }}
            />{" "}
            <Button
              size="small"
              variant="contained"
              color="secondary"
              sx={{
                position: "absolute",
                bottom: "25%",
                left: "15%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                color: "#FFFFFF",
                textTransform: "none",
                fontSize: {
                  xs: "0.7rem",
                  sm: "0.8rem",
                  md: "0.8rem",
                },
              }}
              onClick={() => setShowDropzone(true)}
            >
              Click to change
            </Button>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#FFFFFF",
                // marginTop: "10px",
                width: "60%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                padding: "5px 20px",
                fontSize: {
                  xs: "0.5rem",
                  sm: "0.6rem",
                  md: "0.7rem",
                },
              }}
              justifyContent="flex-start"
            >
              {imageName}
            </Typography>
          </Box>
        </Box>

        <ImageDuration
          videoId={videoId}
          imageId={selectedImage}
          initialDuration={1}
        />
      </Box>
      {showDropzone && (
        <SidebarDropzone
          onClose={handleDropzoneClose}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
        />
      )}
    </Grid>
  );
}

export default Sidebar;
