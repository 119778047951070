import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import Empty from "../../Images/NoVideos.png";
import WizardEdit from "../EditScreen/Wizard/WizardEdit";

const EmptyState = () => {
  const [isWizardEditOpen, setIsWizardEditOpen] = useState(false);
  const handleCloseWizard = () => {
    setIsWizardEditOpen(false);
  };

  const CreateNewVideo = async () => {
    setIsWizardEditOpen(true);
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      // height="100vh"
      bgcolor="transparent"
      color="#ffffff"
    >
      <img src={Empty} alt="nothing here" height="200px" />
      <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
        It's looking a little empty in here
      </Typography>
      <Typography variant="body1" sx={{ mb: 3, color: "#62FFB4" }}>
        Create your first video.
      </Typography>
      {isWizardEditOpen && <WizardEdit onClose={handleCloseWizard} />}
      <Button
        variant="contained"
        color="primary"
        onClick={CreateNewVideo}
        sx={{
          bgcolor: "#62FFB4",
          color: "#000",
          "&:hover": {
            bgcolor: "#4bcfa6",
          },
        }}
      >
        Create a video
      </Button>
    </Box>
  );
};

export default EmptyState;
