import React from "react";
import {
  Typography,
  Button,
  Grid,
  ImageList,
  ImageListItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import "../../Styles/Page.css"; // Import the CSS file

import image1 from "../../Images/Intropage/hero/1.jpg";
import image2 from "../../Images/Intropage/hero/2.jpg";
import image3 from "../../Images/Intropage/hero/3.jpg";
import image4 from "../../Images/Intropage/hero/4.jpg";
import image5 from "../../Images/Intropage/hero/5.jpg";
import image6 from "../../Images/Intropage/hero/6.jpg";
import image7 from "../../Images/Intropage/hero/7.jpg";
import image8 from "../../Images/Intropage/hero/8.jpg";
import image9 from "../../Images/Intropage/hero/9.jpg";
import image10 from "../../Images/Intropage/hero/10.jpg";
import image11 from "../../Images/Intropage/hero/11.jpg";
import image12 from "../../Images/Intropage/hero/12.jpg";

function Page() {
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
  ];

  // const theme = useTheme();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      sx={{
        backgroundColor: "black",
        maxHeight: { xs: "120vh", md: "100vh" },
        maxWidth: "100vw",
        padding: { xs: "0px", md: "0" },
      }}
    >
      <Grid
        item
        xs={12}
        md={7}
        sx={{ padding: { xs: "20px", md: "70px 120px" }, color: "white" }}
      >
        <Typography
          variant="h1"
          sx={{
            marginBottom: { xs: "5px", md: "10px " },
            lineHeight: { xs: "35px", md: "40px" },
            fontWeight: 600,
            fontSize: { xs: "2rem", sm: "2rem", md: "2.5rem" },
          }}
        >
          Losing a loved one is hard. Remembering them should be easy.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 300,
            marginBottom: "10px",
            fontSize: {
              xs: "0.8rem",
              sm: "0.9rem",
              md: "1rem",
              lg: "1.1rem",
            },
          }}
        >
          Easily create personalised memorial videos that celebrate the life of
          your loved ones. Choose from a variety of backgrounds and music,
          upload photos, and preview a watermarked version of your video before
          purchasing the final product.
        </Typography>
        <Button
          component={RouterLink}
          to="/signup"
          variant="contained"
          sx={{
            backgroundColor: "#62FFB4",
            color: "black",
            marginTop: "20px",
            borderRadius: "10px",
            "&:hover": {
              backgroundColor: "#62FFB4", // Same background color on hover
            },
          }}
        >
          Get Started
        </Button>
      </Grid>

      {/* Left Column for Downward Animation */}
      <Grid
        item
        xs={6}
        md={2.5}
        sx={{
          overflow: "hidden", // Hide overflow to prevent scrollbars
          maxHeight: { xs: "50vh", lg: "90vh" },
          paddingRight: 2,
        }}
      >
        <ImageList
          variant="masonry"
          cols={1}
          gap={8}
          className="downward-scroll"
        >
          {images.map((img, index) => (
            <ImageListItem key={`left-${index}`}>
              <img
                src={img}
                alt={`image-${index}`}
                style={{ width: "100%", minHeight: "auto" }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>

      {/* Right Column for Upward Animation */}
      <Grid
        item
        xs={6}
        md={2.5}
        sx={{
          overflow: "hidden", // Hide overflow to prevent scrollbars
          maxHeight: { xs: "50vh", lg: "90vh" },
          paddingRight: 2,
        }}
      >
        <ImageList variant="masonry" cols={1} gap={8} className="upward-scroll">
          {images.map((img, index) => (
            <ImageListItem key={`right-${index}`}>
              <img
                src={img}
                alt={`image-${index}`}
                style={{ width: "100%", minHeight: "auto" }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>
    </Grid>
  );
}

export default Page;
