import { useVideoContext } from "../Context/VideoContext";
import { useAuthContext } from "./useAuthContext";
import axios from "axios";

export const useRorderImages = () => {
  const { user } = useAuthContext();
  const { videoId } = useVideoContext();
  const RorderImages = async (images) => {
    if (!user) {
      alert("You must be logged in to update the image");
      return;
    }

    try {
      await axios.patch(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/video/${videoId._id}/updateOrder`,
        {
          images: images,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log("Images Reorder successfully");
    } catch (error) {
      console.error("Error reordering image:", error);
    }
  };

  return { RorderImages };
};
