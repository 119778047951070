import { useAuthContext } from "./useAuthContext";
import axios from "axios";
import { useCallback, useState } from "react";
import { useBackgroundContext } from "../Context/BackgroundContext";

export const useGetAllBackgrounds = () => {
  const { user } = useAuthContext();
  const { setBackgroundImage } = useBackgroundContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const GetBackgrounds = useCallback(async () => {
    if (!user) {
      alert("You must be logged in to get the Backgrounds");
      return null;
    }
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/background`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setBackgroundImage(response.data);
    } catch (error) {
      console.error("Error fetching Backgrounds:", error);
      setError("Error fetching Backgrounds");
      return false;
    } finally {
      setLoading(false);
    }
  }, [user, setBackgroundImage]);

  return { GetBackgrounds, loading, error };
};
