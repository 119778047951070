import { createContext, useState, useEffect, useContext } from "react";
// Image Context
const ImageContext = createContext();

export const useImageContext = () => useContext(ImageContext);

export const ImageContextProvider = ({ children }) => {
  const [imageId, setImageId] = useState([]);

  useEffect(() => {
    const storedImageId = window.localStorage.getItem("imageId");
    if (storedImageId) {
      setImageId(JSON.parse(storedImageId));
    }
  }, []);

  useEffect(() => {
    if (imageId.length > 0) {
      window.localStorage.setItem("imageId", JSON.stringify(imageId));
    }
  }, [imageId]);

  return (
    <ImageContext.Provider value={{ imageId, setImageId }}>
      {children}
    </ImageContext.Provider>
  );
};
