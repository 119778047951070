import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./Config/materialTheme";
import "./index.css";
import App from "./App";

import { AuthContextProvider } from "./Context/AuthContext";
import { VideoContextProvider } from "./Context/VideoContext";
import { ImageContextProvider } from "./Context/ImageContext";
import { BackgroundContextProvider } from "./Context/BackgroundContext";
import { FormatContextProvider } from "./Context/FormatContext";
import { SongContextProvider } from "./Context/SongContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <VideoContextProvider>
        <ImageContextProvider>
          <BackgroundContextProvider>
            <SongContextProvider>
              <FormatContextProvider>
                <ThemeProvider theme={theme}>
                  <App />
                </ThemeProvider>
              </FormatContextProvider>
            </SongContextProvider>
          </BackgroundContextProvider>
        </ImageContextProvider>
      </VideoContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);
