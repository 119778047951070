import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import aspectImage from "../../Images/Intropage/pages/aspect.png";
import musicImage from "../../Images/Intropage/pages/music.png";
import backgroundsImage from "../../Images/Intropage/pages/backgrounds.png";
import uploadImage from "../../Images/Intropage/pages/upload.png";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import MusicVideoOutlinedIcon from "@mui/icons-material/MusicVideoOutlined";
import AspectRatioOutlinedIcon from "@mui/icons-material/AspectRatioOutlined";

function PageTwo() {
  const [currentImage, setCurrentImage] = useState(backgroundsImage);
  const [hoveredOption, setHoveredOption] = useState("Choose Background");

  const optionToImageMap = {
    "Choose Background": backgroundsImage,
    "Choose Aspect Ratio": aspectImage,
    "Select Music": musicImage,
    "Upload Your Photos": uploadImage,
  };

  const optionToIconMap = {
    "Choose Background": (
      <CollectionsOutlinedIcon sx={{ marginRight: "10px" }} />
    ),
    "Choose Aspect Ratio": (
      <AspectRatioOutlinedIcon sx={{ marginRight: "10px" }} />
    ),
    "Select Music": <MusicVideoOutlinedIcon sx={{ marginRight: "10px" }} />,
    "Upload Your Photos": (
      <FileDownloadOutlinedIcon sx={{ marginRight: "10px" }} />
    ),
  };

  // Array of options for easy cycling
  const options = Object.keys(optionToImageMap);
  let optionIndex = options.indexOf(hoveredOption);

  useEffect(() => {
    const interval = setInterval(() => {
      optionIndex = (optionIndex + 1) % options.length; // Cycle through options
      setHoveredOption(options[optionIndex]);
      setCurrentImage(optionToImageMap[options[optionIndex]]);
    }, 2000);

    return () => clearInterval(interval); // Clear interval on unmount
  }, [hoveredOption]);

  const getHoverStyles = (option) => ({
    backgroundColor: hoveredOption === option ? "#13251F" : "transparent",
    width: { xs: "70%", sm: "50%", md: "40%", lg: "40%", xl: "30%" },
    cursor: "pointer",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    paddingX: "40px",
    fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
  });

  return (
    <Box
      sx={{ backgroundColor: "black", maxHeight: "auto", padding: 2, mb: 1 }}
    >
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: { xs: "20px", md: "50px" } }}
      >
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            color: "white",
            textAlign: { xs: "center", md: "left" },
            padding: { xs: 2, md: 4 },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              marginBottom: "10px",
              width: { xs: "100%", md: "80%" },
              margin: "20px auto",
            }}
          >
            <Typography
              sx={{
                lineHeight: { xs: "35px", md: "40px" },
                fontWeight: 600,
                fontSize: { xs: "2rem", sm: "2rem", md: "2.5rem" },
                width: { xs: "100%", md: "90%" },
              }}
            >
              Personalize Your Memorial Videos With Ease
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            sx={{
              lineHeight: "normal",
              marginBottom: "20px",
              width: { xs: "80%", md: "80%" },
              margin: "5px auto",
              fontSize: { xs: "0.9rem", sm: "1rem", md: "1.2rem" },
            }}
          >
            Upload photos of your dear ones, choose from a variety of
            backgrounds and music, and create personalised memorial videos that
            truly honor your loved ones.
          </Typography>
          <Box
            sx={{
              width: { xs: "80%", md: "80%" },
              margin: "40px auto",
              // gap: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            {options.map((option, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={getHoverStyles(option)}
                onMouseEnter={() => {
                  setCurrentImage(optionToImageMap[option]);
                  setHoveredOption(option);
                }}
                onMouseLeave={() => setHoveredOption("Choose Background")}
              >
                {optionToIconMap[option]}
                {option}
              </Typography>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={5} display={"flex"} justifyContent="center">
          <Box sx={{ width: { xs: "370px", md: "600px" }, height: "auto" }}>
            <img
              src={currentImage}
              alt="Preview"
              style={{ width: "100%", height: "auto", borderRadius: "10px" }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PageTwo;
