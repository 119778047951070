import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import previewImage from "../../Images/Intropage/pages/preview.png";

function PageThree() {
  return (
    <Box sx={{ backgroundColor: "#13251F", minHeight: "100vh", padding: 2 }}>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: { xs: "20px", md: "55px" } }}
      >
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            color: "white",
            textAlign: { xs: "center", md: "left" },
            padding: { xs: 2, md: 4 },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              lineHeight: { xs: "35px", md: "40px" },
              fontWeight: 600,
              fontSize: { xs: "2rem", sm: "2rem", md: "2.5rem" },
              textAlign: { xs: "center", md: "left" },
              marginBottom: "10px",
              width: { xs: "100%", md: "80%" },
              margin: "20px auto",
            }}
          >
            Preview Videos without any payment with watermark
          </Typography>
          <Typography
            variant="body1"
            sx={{
              lineHeight: "normal",
              marginBottom: "20px",
              textAlign: { xs: "center", md: "left" },
              width: { xs: "80%", md: "80%" },
              margin: "5px auto",
              fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
            }}
          >
            Our preview feature allows you to view a watermarked version of your
            video before purchase. You can also edit it as many times as you
            want before you pay.
          </Typography>
          <Grid
            container
            spacing={2}
            mt={5}
            display={"flex"}
            justifyContent="center"
          >
            <Grid item xs={12} sm={5}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "center", sm: "flex-start" },
                  textAlign: { xs: "center", sm: "left" },
                }}
              >
                <SettingsSuggestOutlinedIcon fontSize="large" />
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", marginBottom: "5px" }}
                >
                  Customise
                </Typography>
                <Typography variant="body1">
                  View watermarked version of your video in seconds
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "center", sm: "flex-start" },
                  textAlign: { xs: "center", sm: "left" },
                }}
              >
                <RemoveRedEyeOutlinedIcon fontSize="large" />
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", marginBottom: "5px" }}
                >
                  Preview
                </Typography>
                <Typography variant="body1">
                  Customise your video with Personal Photos, Backgrounds, and
                  Music
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5} display={"flex"} justifyContent="center">
          <Box
            sx={{
              width: { xs: "370px", md: "600px" },
              height: "auto",
            }}
          >
            <img
              src={previewImage}
              alt=" here"
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PageThree;
