import React from "react";
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Navbar from "./Navbar";
import HomepageFooter from "./HomepageFooter";

function FeaturesIntroPage() {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          backgroundColor: "primary",
          color: "white",
          minHeight: "100vh",
          padding: { xs: 3, sm: 4, md: 6 },
        }}
      >
        {/* Main Heading */}
        <Typography
          variant="h3"
          sx={{
            fontWeight: 600,
            fontSize: { xs: "1.8rem", sm: "2.4rem", md: "2.8rem" },
            textAlign: "left",
            marginBottom: 3,
          }}
        >
          Features
        </Typography>

        {/* Introductory Text */}
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
            lineHeight: 1.6,
            textAlign: "left",
            marginBottom: 4,
          }}
        >
          Our memorial video service provides all the tools you need to create a
          meaningful tribute to your loved one. Designed for simplicity and
          elegance, our service guides you through each step of the process—from
          selecting photos to personalizing backgrounds and music—so you can
          create a video that truly honors their memory.
        </Typography>

        <Divider sx={{ my: 4, borderColor: "white" }} />

        {/* Key Features Section */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            mb: 2,
            fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2rem" },
          }}
        >
          Key Features
        </Typography>
        <List disablePadding sx={{ marginBottom: 4 }}>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="1. Simple Image Upload and Arrangement" />
            <Typography variant="body2" sx={{ color: "white", ml: 3 }}>
              Easily upload between 10 and 60 photos through our drag-and-drop
              interface. You can organize and arrange photos to tell a
              beautiful, personalized story. Simple editing tools make it easy
              to adjust the order, ensuring each memory is displayed exactly as
              you wish.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="2. Background Choices to Set the Right Tone" />
            <Typography variant="body2" sx={{ color: "white", ml: 3 }}>
              Select from a range of calming and respectful backgrounds,
              including options like serene seas, fluffy clouds, majestic
              mountains, peaceful waterfalls, and more. Each background has been
              thoughtfully chosen to enhance the video’s atmosphere.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="3. Music Selection for an Emotional Touch" />
            <Typography variant="body2" sx={{ color: "white", ml: 3 }}>
              Choose from five carefully curated, non-copyrighted songs that
              evoke peace and remembrance. The right music can add depth to your
              video.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="4. Watermarked Preview for Peace of Mind" />
            <Typography variant="body2" sx={{ color: "white", ml: 3 }}>
              As you create your video, you’ll have the opportunity to preview
              it with a watermark to ensure every detail is just right. This
              lets you review the entire tribute before making a final purchase
              decision.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="5. Easy and Secure Payment" />
            <Typography variant="body2" sx={{ color: "white", ml: 3 }}>
              Our system uses Stripe, a trusted and secure payment processor,
              ensuring your information is protected. After payment is complete,
              the final, unwatermarked video will be processed and delivered.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="6. Automated Delivery of Your Final Video" />
            <Typography variant="body2" sx={{ color: "white", ml: 3 }}>
              After payment, you will receive an email with a link to download
              the completed memorial video. This link allows you to save the
              video to your device and share it with family and friends.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="7. User-Friendly, Guided Process" />
            <Typography variant="body2" sx={{ color: "white", ml: 3 }}>
              Our service is designed with simplicity in mind, using a
              step-by-step guide that takes you through each part of the video
              creation process. From selecting photos to adding music and
              backgrounds, our intuitive interface ensures a smooth experience.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="8. High-Quality Video Output" />
            <Typography variant="body2" sx={{ color: "white", ml: 3 }}>
              We use advanced video processing tools to create a
              professional-quality video that is clear, smooth, and visually
              appealing. Each tribute video can run up to 5 minutes, capturing a
              lifetime of memories in a beautiful presentation.
            </Typography>
          </ListItem>
        </List>

        <Divider sx={{ my: 4, borderColor: "white" }} />

        {/* Closing Statement */}
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
            lineHeight: 1.6,
            textAlign: "left",
          }}
        >
          Our memorial video service brings together these features to help you
          create a meaningful tribute that honors your loved one’s life and
          legacy. With thoughtful customization options and an easy-to-follow
          process, we’re here to support you every step of the way. Thank you
          for allowing us to be a part of this important remembrance.
        </Typography>
      </Box>
      <HomepageFooter />
    </>
  );
}

export default FeaturesIntroPage;
