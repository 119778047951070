import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { usePayment } from "../../Hooks/usePayment"; // Adjust the path as needed
import { useGetAllVideos } from "../../Hooks/useGetAllVideos"; // Adjust the path as needed
import { useVideoContext } from "../../Context/VideoContext";
import { CircularProgress, Box, Typography, Button, Fade } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
const SuccessPayment = () => {
  const { user } = useAuthContext();
  const { GetVideoRefresh } = useGetAllVideos();
  const { loading, GeneratePaidVideo, sendEmail } = usePayment();
  const { videoId } = useVideoContext();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const sessionId = urlParams.get("session_id");
  const videoID = urlParams.get("videoId");
  const [isVideoGenerated, setIsVideoGenerated] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    const updatePaymentStatus = async () => {
      if (!user) {
        console.error("User is not authenticated");
        return;
      }
      if (videoId.paymentStatus === "paid") {
        console.error("Already paid");
        return;
      }

      try {
        await axios.post(
          `${process.env.REACT_APP_SERVER_DOMAIN}/api/payment/success`,
          {
            sessionId: sessionId,
            videoId: videoId._id,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        console.log("Video payment status updated successfully");
        GetVideoRefresh(videoID);
      } catch (error) {
        console.error("Error updating payment status:", error);
      }
    };

    updatePaymentStatus(videoId._id);
  }, [user, location.search, GetVideoRefresh, videoId, sessionId, videoID]);

  useEffect(() => {
    if (
      !isVideoGenerated &&
      !videoId.videoLink &&
      videoId.paymentStatus === "paid"
    ) {
      const generateVideo = async () => {
        setIsVideoGenerated(true);
        await GeneratePaidVideo(videoId._id);
        await GetVideoRefresh(videoId._id);
      };
      console.log("Generating video");
      generateVideo();
    }
  }, [GeneratePaidVideo, GetVideoRefresh, videoId, isVideoGenerated]);
  const handleGoBack = () => {
    navigate("/dashboard");
  };
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const messages = [
    "Exporting video... it may take a while",
    "Video Link will be sent to you via email",
    "Stay with us while the video is being generated",
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 3000);

    return () => clearInterval(timer);
  }, [messages.length]);
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="#181C1F"
      color="#FFFFFF"
      textAlign="center"
      padding={4}
    >
      <Box
        bgcolor="#000000"
        padding={4}
        borderRadius={2}
        boxShadow={3}
        sx={{
          "&:hover": {
            boxShadow: 6,
          },
        }}
      >
        {loading ? (
          <>
            <Box textAlign="center" width="400px" mt={2}>
              <CircularProgress color="success" />
              {messages.map((message, index) => (
                <Fade
                  in={currentMessageIndex === index}
                  key={index}
                  timeout={{ enter: 500, exit: 500 }}
                >
                  <Typography
                    variant="h6"
                    marginTop={2}
                    style={{
                      display: currentMessageIndex === index ? "block" : "none",
                    }}
                  >
                    {message}
                  </Typography>
                </Fade>
              ))}
            </Box>
          </>
        ) : sendEmail ? (
          <>
            <CheckCircleIcon color="success" sx={{ fontSize: 60 }} />
            <Typography variant="h4">Payment Successful!</Typography>
            <Typography variant="body1">
              Thank you for your purchase.{" "}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleGoBack}
              sx={{ marginTop: 4 }}
            >
              GO TO HOME
            </Button>
          </>
        ) : (
          <>
            <CheckCircleIcon color="success" sx={{ fontSize: 60 }} />
            <Typography variant="h4">Payment Successful!</Typography>
            <Typography variant="body1">
              Thank you for your purchase.{" "}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleGoBack}
              sx={{ marginTop: 4 }}
            >
              GO TO HOME
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SuccessPayment;
