import { useState } from "react";
import axios from "axios";
import { useAuthContext } from "./useAuthContext";
import { useVideoContext } from "../Context/VideoContext";

const useDeleteImage = () => {
  const { videoId } = useVideoContext();
  const { user } = useAuthContext();
  const [deleteImageLoading, setDeleteImageLoading] = useState(false);
  const [deleteImageError, setDeleteImageError] = useState(null);

  const deleteImage = async (imageUrl) => {
    if (!user) {
      alert("You must be logged in to delete the image");
      return;
    }

    setDeleteImageLoading(true);
    setDeleteImageError(null);

    try {
      const imageId = imageUrl.split("/").pop();
      const response = await axios.delete(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/video/${videoId._id}/images/${imageId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log("Image deleted");
      return response.data;
    } catch (error) {
      console.error("Error deleting image:", error);
      setDeleteImageError(error);
      throw error;
    } finally {
      setDeleteImageLoading(false);
    }
  };

  return { deleteImage, deleteImageLoading, deleteImageError };
};

export default useDeleteImage;
