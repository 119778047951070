import { useEffect, useState } from "react";
import { io } from "socket.io-client";

const useSocket = (serverUrl) => {
  const [socket, setSocket] = useState(null);
  const [progress, setProgress] = useState(null);

  useEffect(() => {
    const socketIo = io(serverUrl);
    socketIo.on("connect", () => {
      console.log("Connected to WebSocket");
      console.log(socketIo.id);
      setSocket(socketIo.id);
    });

    socketIo.on("progress", (data) => {
      setProgress(data);
      console.log("Progress update:", data);
    });

    socketIo.on("disconnect", () => {
      console.log("Disconnected from WebSocket");
      setSocket(socketIo.id);
    });

    // setSocket(socketIo.id);

    return () => {
      socketIo.disconnect(); // Clean up the connection when the component unmounts
    };
  }, [serverUrl]);

  return { socket, progress };
};

export default useSocket;
