import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "../../../Styles/PopupWindow.css";
// import BackgroundSelector from "./BackgroundSelector";
import HorizontalBackground from "../Wizard/HorizontalBackground";
import AddSong from "./AddSong";
import AddFormat from "./AddFormat";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import ImageIcon from "@mui/icons-material/Image";
import FitScreenRoundedIcon from "@mui/icons-material/FitScreenRounded";

function PopupWindow() {
  const [stepNumber, setStepNumber] = useState(null);
  const [clicked, setClicked] = useState(null);
  const [text, setText] = useState("Audio");
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("md"));
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });

  const handleOpen = (step, text) => {
    // Set the state as usual
    setStepNumber(step);
    setClicked(step);
    setText(text);
    console.log("step", stepNumber);
    console.log("click", clicked);

    const buttonContainer = document.getElementById("button-container");

    if (buttonContainer) {
      const buttonContainerRect = buttonContainer.getBoundingClientRect();

      if (isXs) {
        setPopupPosition({
          top: window.scrollY + 70,
          left: 0,
        });
      } else {
        // Use the `step` variable directly instead of `stepNumber`
        setPopupPosition({
          top: buttonContainerRect.bottom + window.scrollY,
          left:
            buttonContainerRect.left +
            window.scrollX +
            buttonContainerRect.width / 2 -
            (step === 3 ? 300 : 400), // Use `step` instead of `stepNumber`
        });
      }
    }
  };

  const handleClose = () => {
    setStepNumber(null);
    setClicked(null);

    // Pause all audio elements in the document
    const audios = document.getElementsByTagName("audio");
    for (let i = 0; i < audios.length; i++) {
      audios[i].pause();
      audios[i].currentTime = 0; // Optionally reset the playback position to the start
    }
  };

  const handleClickOutside = (event) => {
    if (
      !event.target.closest("#popup-container") &&
      !event.target.closest("#button-container")
    ) {
      handleClose();
    }
  };

  useEffect(() => {
    if (stepNumber !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [stepNumber]);

  return (
    <Box sx={{ textAlign: "center" }}>
      <Box
        id="button-container"
        display="flex"
        justifyContent={"center"}
        sx={{
          flexDirection: { xs: "column", md: "row" },
          gap: { xs: 6, md: 0 },
        }}
      >
        <Button
          sx={{
            color: { xs: "#62FFB4", md: "white" },
            justifyContent: { xs: "flex-end", md: "flex-start" },
            fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem", lg: "1.5rem" },
          }}
          onClick={() => handleOpen(3, "AUDIO")}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderBottom: clicked === 3 ? "2px solid #3F806F" : "none", // Underline applied to both icon and text
            }}
          >
            <AudiotrackIcon
              sx={{
                fontSize: { xs: 30, sm: 35, md: 20, lg: 20 },
                mr: { xs: "1px", sm: "4px", md: "4px", lg: "3px" },
              }}
            />
            <Typography
              sx={{
                display: { xs: "none", md: "flex", textTransform: "none" },
                fontSize: {
                  xs: "0.7rem",
                  sm: "0.7rem",
                  md: "0.8rem",
                  lg: "1rem",
                },
                fontWeight: "200",
              }}
            >
              Audio
            </Typography>
          </Box>
        </Button>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{
            backgroundColor: "grey",
            mx: { xs: "1px", sm: "1px", md: "1px", lg: "3px" },
            display: { xs: "none", sm: "flex" },
          }}
        />
        <Button
          sx={{
            color: { xs: "#62FFB4", md: "white" },
            justifyContent: { xs: "flex-end", md: "flex-start" },
            fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem", lg: "1.5rem" },
          }}
          onClick={() => handleOpen(1, "Select your desired Background")}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderBottom: clicked === 1 ? "2px solid #3F806F" : "none", // Underline applied to both icon and text
            }}
          >
            <ImageIcon
              sx={{
                fontSize: { xs: 30, sm: 35, md: 20, lg: 20 },
                mr: { xs: "1px", sm: "4px", md: "4px", lg: "3px" },
              }}
            />
            <Typography
              sx={{
                display: { xs: "none", md: "flex", textTransform: "none" },
                fontSize: {
                  xs: "0.7rem",
                  sm: "0.7rem",
                  md: "0.8rem",
                  lg: "1rem",
                },
                fontWeight: "200",
              }}
            >
              Background
            </Typography>
          </Box>
        </Button>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{
            backgroundColor: "grey",
            mx: { xs: "1px", sm: "1px", md: "1px", lg: "3px" },
            display: { xs: "none", sm: "flex" },
          }}
        />
        <Button
          sx={{
            color: { xs: "#62FFB4", md: "white" },
            justifyContent: { xs: "flex-end", md: "flex-start" },
            fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem", lg: "1.5rem" },
          }}
          onClick={() => handleOpen(2, "Choose Your Video Format")}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderBottom: clicked === 2 ? "2px solid #3F806F" : "none", // Underline applied to both icon and text
            }}
          >
            <FitScreenRoundedIcon
              sx={{
                fontSize: { xs: 30, sm: 35, md: 20, lg: 20 },
                mr: { xs: "1px", sm: "4px", md: "4px", lg: "3px" },
              }}
            />
            <Typography
              sx={{
                display: { xs: "none", md: "flex", textTransform: "none" },
                fontSize: {
                  xs: "0.7rem",
                  sm: "0.7rem",
                  md: "0.8rem",
                  lg: "1rem",
                },
                fontWeight: "200",
              }}
            >
              Format
            </Typography>
          </Box>
        </Button>
      </Box>
      <Box
        id="popup-container"
        sx={{
          position: "fixed",
          top: `${popupPosition.top}px`,
          left: `${popupPosition.left}px`,
          width: {
            xs: "95vw",
            md: "650px",
            lg: stepNumber === 3 ? "530px" : "790px", // Set width for step 3 on large screens
          },
          display: stepNumber ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          backgroundColor: "#14181B",
          p: { xs: "0", sm: "2" },
          margin: "0px 10px",
          borderRadius: "10px",
          zIndex: 9999,
          color: "white",
        }}
      >
        {stepNumber !== 3 && (
          <Typography
            variant="h6"
            fontWeight={200}
            ml={isXs ? 1 : 9}
            paddingTop={3}
            paddingLeft={1}
            textAlign="start"
          >
            {text}
          </Typography>
        )}
        {
          stepNumber === 1 && (
            // (isXs ? (
            <HorizontalBackground handleClose={handleClose} />
          )
          // ) : (
          // <BackgroundSelector handleClose={handleClose} />
          // ))
        }
        {stepNumber === 3 && <AddSong handleClose={handleClose} />}
        {stepNumber === 2 && <AddFormat handleClose={handleClose} />}
      </Box>
    </Box>
  );
}

export default PopupWindow;
