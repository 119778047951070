import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import useImageDuration from "../../../Hooks/useImageDuration";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CircularProgress from "@mui/material/CircularProgress";

const ImageDuration = ({ videoId, imageId, initialDuration }) => {
  const [showCheck, setShowCheck] = useState(false);
  const [duration, setDuration] = useState(initialDuration);
  const [loadingDuration, setLoadingDuration] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const { updateDuration, getImageDuration, loading, error } =
    useImageDuration();

  useEffect(() => {
    const fetchDuration = async () => {
      setLoadingDuration(true);
      try {
        setShowCheck(false);
        const fetchedDuration = await getImageDuration(videoId, imageId.id);
        setDuration(fetchedDuration);
      } catch (error) {
        console.error("Failed to fetch image duration", error);
      } finally {
        setLoadingDuration(false);
      }
    };
    if (imageId) fetchDuration();
  }, [imageId]);

  const handleAdd = () => {
    setDuration((prev) => prev + 1);
    setShowCheck(true);
  };

  const handleRemove = () => {
    setDuration((prev) => (prev > 0 ? prev - 1 : 0));
    setShowCheck(true);
  };

  const handleUpdate = async () => {
    try {
      await updateDuration(videoId, imageId.id, duration);
      setShowCheck(false);
      setSuccessMessage("Duration updated successfully");
      setTimeout(() => setSuccessMessage(""), 2000);
    } catch (error) {
      setShowCheck(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        backgroundColor: "#181C1F",
        color: "#FFFFFF",
      }}
    >
      <Typography
        variant="subtitle1"
        // component="div"
        sx={{
          color: "#FFFFFF",
          marginBottom: { xs: "10px", md: "15px" },
          fontSize: {
            xs: "0.9rem",
            sm: "0.9rem",
            md: "1rem",
          },
        }}
      >
        Image Clip Duration
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          flexDirection: "column", // Arrange the elements in a column
          minWidth: "150px",
        }}
      >
        {/* Main Box with Add/Remove Icons */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#62FFB466",
            padding: "5px 12px",
            borderRadius: "3px",
          }}
        >
          <IconButton sx={{ padding: 0 }} onClick={handleRemove}>
            <RemoveIcon
              sx={{
                color: "#FFFFFF",
                fontSize: "18px",
                border: "1px solid #FFFFFF",
                borderRadius: "50%",
              }}
            />
          </IconButton>
          <Typography
            variant="body2"
            sx={{
              color: "#FFFFFF",
              margin: "0 8px",
              width: 25, // Fixed width to prevent displacement
              height: 25, // Fixed height to prevent displacement
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "13px",
            }}
          >
            {loadingDuration ? (
              <CircularProgress size={15} />
            ) : (
              <Box sx={{ width: 15, height: 15 }} /> // Placeholder for CircularProgress
            )}
            {!loadingDuration && `0:${duration.toString().padStart(2, "0")}`}
          </Typography>
          <IconButton sx={{ padding: 0 }} onClick={handleAdd}>
            <AddIcon
              sx={{
                color: "#FFFFFF",
                fontSize: "18px",
                border: "1px solid #FFFFFF",
                borderRadius: "50%",
              }}
            />
          </IconButton>
        </Box>

        {/* Apply Changes Box */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "grey", // Grey background
            borderRadius: "3px", // Rounded borders
            cursor: "pointer",
            marginTop: "10px", // Move to the next line with some margin
            padding: "5px 3px",
            visibility: showCheck ? "visible" : "hidden",
            width: "80%", // Adjust the width to ensure consistent space
          }}
          onClick={handleUpdate}
        >
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            <Typography
              sx={{
                color: "#FFFFFF",
                cursor: "pointer",
                fontSize: "0.8rem",
              }}
            >
              Apply Changes
            </Typography>
          )}
        </Box>
      </Box>

      {successMessage && (
        <Typography
          width={"100%"}
          variant="subtitle2"
          fontSize={"0.8rem"}
          sx={{ color: "green", marginTop: "0px" }}
        >
          {/* Image duration updated successfully */}
          {successMessage}
        </Typography>
      )}
      {error && (
        <Typography variant="body2" sx={{ color: "red", marginTop: "20px" }}>
          Failed to update Duration
        </Typography>
      )}
    </Box>
  );
};

export default ImageDuration;
