import axios from "axios";
import { useAuthContext } from "./useAuthContext";

const useDeleteVideo = () => {
  const { user } = useAuthContext();

  const deletevideo = async (videoUrl) => {
    if (!user) {
      alert("You must be logged in to delete the video");
      return;
    }
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/video/${videoUrl}/delete`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log("video deleted");
      return response.data;
    } catch (error) {
      console.error("Error deleting video:", error);
      throw error;
    }
  };

  return { deletevideo };
};

export default useDeleteVideo;
