import React from "react";
import Navbar from "./Navbar";
import Page from "./Page";
import PageTwo from "./PageTwo";
import PageThree from "./PageThree";
import PageFour from "./PageFour";
import PageFive from "./PageFive";
import { Box } from "@mui/material";
import Slideshow from "./slideshow";
import HomepageFooter from "./HomepageFooter";
function IntroPage() {
  return (
    <Box sx={{ height: "auto", maxWidth: "100vw", overflowX: "hidden" }}>
      <Navbar />
      <Page />
      <PageFive />
      <PageTwo />
      <PageThree />
      <PageFour />
      <Slideshow />
      <HomepageFooter />
    </Box>
  );
}

export default IntroPage;
